import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Tab,
  TabBar,
} from '@rmwc/tabs';

import ChatRoomRow from './ChatRoomRow';
import BulletinBoardPostsHeader from '../bulletin-board-posts/employee/BulletinBoardPostsHeader';
import BulletinBoardPostRow from '../bulletin-board-posts/employee/BulletinBoardPostRow';
import BulletinBoardCategoryRow from '../bulletin-board-posts/employee/BulletinBoardCategoryRow';
import { appcontentsize } from '../styles/inline-styles';

const rearrangeRooms = (currentUser, rooms) => [
  ...rooms.map((room) => (currentUser.invitationsByWorkOrderId[room.workorder.id] != null ? room : null)),
  ...rooms.map((room) => (currentUser.invitationsByWorkOrderId[room.workorder.id] == null ? room : null)),
].filter((item) => item != null);

const renderChatrooms = (currentUser, rooms, unreadMessages) => {
  const chatRooms = rearrangeRooms(currentUser, rooms || []);
  return (
    <div {...{
      style: appcontentsize,
    }}
    >
      {
        chatRooms.length > 0 ? (
          chatRooms.map((room) => (
            <ChatRoomRow
              key={`chat-room-${room.id}`}
              room={room}
              unreadMessages={unreadMessages[room.id] != null ? unreadMessages[room.id].length : 0}
            />
          ))
        ) : (
          <p
            style={{ textAlign: 'center' }}
          >
            Viestiryhmiä ei löytynyt
          </p>
        )
      }
    </div>
  );
};

const renderBulletinBoardCategories = (currentUser, openBulletinBoardCategory) => (
  <div {...{
    style: appcontentsize,
  }}
  >
    {
      currentUser.bulletinBoardCategories.length > 0 ? (
        currentUser.bulletinBoardCategories.map((category) => (
          <BulletinBoardCategoryRow
            key={`bulletin-board-category-${category.id}`}
            bulletinBoardCategory={category}
            openBulletinBoardCategory={openBulletinBoardCategory}
          />
        ))
      ) : (
        <p
          style={{ textAlign: 'center' }}
        >
          Ilmoituksia ei löytynyt
        </p>
      )
    }
  </div>
);

const renderBulletinBoardPosts = (openedCategory, closeBulletinBoardCategory) => (
  <div {...{
    style: appcontentsize,
  }}
  >
    <BulletinBoardPostsHeader bulletinBoardCategory={openedCategory} closeBulletinBoardCategory={closeBulletinBoardCategory} />
    <div style={{ marginTop: '25px' }}>
      {
        openedCategory.posts.length > 0 && (
          openedCategory.posts.map((post) => (
            <BulletinBoardPostRow
              key={`bulletin-board-post-${post.id}`}
              bulletinBoardPost={post}
            />
          ))
        )
      }
    </div>
  </div>
);

@inject('uiStore', 'chatStore', 't')
@observer
class Chats extends Component {
  constructor(props) {
    super(props);
    const { chatStore, uiStore: { currentView: { messagesTab } } } = this.props;
    if (!messagesTab || messagesTab === 0) {
      chatStore.refreshRooms();
    }

    this.state = {
      activeTab: messagesTab || 0,
    };
  }

  openBulletinBoardCategory = (bulletinBoardCategory) => {
    const { uiStore } = this.props;
    uiStore.showBulletinBoardCategory(bulletinBoardCategory.id);
    // this.setState({
    //   bulletinBoardCategoryOpen: true,
    //   openedCategory: bulletinBoardCategory,
    // });
  }

  render() {
    const { chatStore, uiStore: { currentUser, currentUser: { accountInfo: { bulletinBoardEnabled }, bulletinBoardCategories } } } = this.props;
    const { rooms, unreadMessages } = chatStore;
    const { activeTab, bulletinBoardCategoryOpen, openedCategory } = this.state;

    // if (bulletinBoardEnabled && categorizedBulletinBoardPosts && categorizedBulletinBoardPosts.length > 0) {
    if (bulletinBoardEnabled && bulletinBoardCategories && bulletinBoardCategories.length > 0) {
      return (
        <>
          <TabBar
            // className="employee-profile-tabs"
            activeTabIndex={activeTab}
            onActivate={(evt) => this.setState({ activeTab: evt.detail.index })}
            style={{
              ...appcontentsize,
              // marginBottom: '1em',
              width: '25wv',
            }}
          >
            <Tab icon="message">Keikan viestit</Tab>
            <Tab icon="assignment">Ilmoitustaulu</Tab>
          </TabBar>

          {/* Note: this HTML structure is significantly different from the "main" navigation tabs in other views */}
          {/* <div style={{ backgroundColor: '#0D121C' }}>
            <TabBar
              style={{ maxWidth: '700px' }}
              activeTabIndex={activeTab}
              onActivate={(evt) => this.setState({ activeTab: evt.detail.index })}
            >
              <Tab icon="account_circle">Tiedot</Tab>
              <Tab icon="fact_check">Voimassaolot</Tab>
            </TabBar>
          </div> */}

          {activeTab === 0 && (
            <>
              {rooms.case({
                pending: () => <p>Ladataan viestejä...</p>,
                rejected: () => <p>Viestejä ei ole ladattu, yritä kohta uudelleen!</p>,
                fulfilled: () => renderChatrooms(currentUser, rooms.value, unreadMessages),
              })}
              <p
                style={{ fontSize: '12px', margin: 'auto 0 20px 0' }}
              >
                Viestiryhmät piilotetaan 15 päivän päästä keikan päättymisestä
              </p>
            </>
          )}
          {activeTab === 1 && !bulletinBoardCategoryOpen && renderBulletinBoardCategories(currentUser, this.openBulletinBoardCategory)}
          {activeTab === 1 && bulletinBoardCategoryOpen && renderBulletinBoardPosts(openedCategory, this.closeBulletinBoardCategory)}
        </>
      );
    }

    return (
      <>
        {rooms.case({
          pending: () => <p>Ladataan viestejä...</p>,
          rejected: () => <p>Viestejä ei ole ladattu, yritä kohta uudelleen!</p>,
          fulfilled: () => renderChatrooms(currentUser, rooms.value, unreadMessages),
        })}
        <p
          style={{ fontSize: '12px', margin: 'auto 0 20px 0' }}
        >
          Viestiryhmät piilotetaan 15 päivän päästä keikan päättymisestä
        </p>
      </>
    );
  }
}

export default Chats;
