import superagent from 'superagent';
// import actionCable from 'actioncable';
import TimelogEntry from '../timelog/TimelogEntry';
import TripRoute from '../timelog/TripRoute';
import RouteLocation from '../timelog/RouteLocation';
import WorkOrderTrip from '../timelog/WorkOrderTrip';
import StartTrip from '../timelog/StartTrip';
import EndTrip from '../timelog/EndTrip';
import { asDate } from '../utils/moment';
import WorkOrder from '../calendar/WorkOrder';
import BillingAttachment from '../employer-billing/BillingAttachment';
import CloseCallReport from '../models/safety/CloseCallReport';
import AccidentReport from '../models/safety/AccidentReport';
import Product from '../models/product/Product';
import User from '../models/User';
import UserProductCollection from '../models/product/UserProductCollection';
import Validity from '../models/Validity';
import Location from '../models/Location';
import Purchaser from '../models/Purchaser';
import WorkTask from '../employer-work-orders/work-tasks/WorkTask';
import Certificate from '../models/Certificate';
import CertificateType from '../models/CertificateType';
import AbsencePeriod from '../models/AbsencePeriod';
import AbsenceEntry from '../models/AbsenceEntry';
import BulletinBoardPost from '../models/BulletinBoardPost';
import BulletinBoardCategory from '../models/BulletinBoardCategory';

export default (config, stores) => {
  const { API_ROOT } = config;

  const handleErrors = (err) => {
    const { loginStore } = stores;
    if (err && err.response && err.response.status === 401) {
      loginStore.logout();
    }

    // errorStore.setApiError(err);
    throw err;
  };


  const responseBody = (res) => res.body;

  // Request templates that could be used.
  const requests = {
    del: (url) => superagent
      .del(`${API_ROOT}${url}`)
      .set('Accept', 'application/json')
      // .timeout(5000)
      .catch(handleErrors)
      .then(responseBody),
    get: (url, query = null) => superagent
      .get(`${API_ROOT}${url}`)
      .set('Accept', 'application/json')
      .query(query || '')
      // .timeout(5000)
      .catch(handleErrors)
      .then(responseBody),
    getFile: (url, query = null) => superagent
      .get(`${API_ROOT}${url}`)
      .set('Accept', 'application/json')
      .query(query || '')
      .responseType('blob')
      // .timeout(5000)
      .catch(handleErrors)
      .then(responseBody),
    put: (url, body) => superagent
      .put(`${API_ROOT}${url}`, body)
      .set('Accept', 'application/json')
      // .timeout(5000)
      .catch(handleErrors)
      .then(responseBody),
    post: (url, body) => superagent
      .post(`${API_ROOT}${url}`, body)
      .set('Accept', 'application/json')
      // .timeout(5000)
      .then(responseBody)
      .catch(handleErrors),
    postFormData: (url, body) => superagent
      .post(`${API_ROOT}${url}`)
      .send(body)
      // .set('Content-Type', 'multipart/form-data')
      // .type('form')
      .set('Accept', 'application/json')
      // .timeout(5000)
      .then(responseBody)
      .catch(handleErrors),
    putFormData: (url, body) => superagent
      .put(`${API_ROOT}${url}`)
      .send(body)
      // .set('Content-Type', 'multipart/form-data')
      // .type('form')
      .set('Accept', 'application/json')
      // .timeout(5000)
      .then(responseBody)
      .catch(handleErrors),
  };

  // User related requests.
  const Users = {
    current: () => requests.get('/api/users/me'),
    // FIXME: This API should look better because of devise
    login: (email, password) => requests.post('/api/users/sign_in.json', {
      api_user: { email, password },
    }),
    logout: () => requests.del('/api/users/sign_out'),
    update: (user) => requests.put(`/api/users/${user.id}`, {
      user: User.toJson(user),
    }),
    create: (user) => requests.post('/api/users', {
      user: User.toJson(user),
    }),
    sendInvite: (email, salaryPeriodCategoryId) => requests.post('/api/users/invite', { email, salary_period_category_id: salaryPeriodCategoryId }),
    workOrders: (user, offset, limit) => requests.get(`/api/users/${user.id}/work_orders`, { offset, limit }),
    archivedWorkOrders: (user, offset, limit) => requests.get(`/api/users/${user.id}/work_orders/archived_work_orders`, { offset, limit }),
    getEmployerWorkOrdersPaginated: (user, offset, limit, sort) => requests.get(`/api/users/${user.id}/work_orders/employer_work_orders_paginated`, { offset, limit, sort }),
    getEmployerWorkOrdersBillingPaginated: (user, offset, limit, sort) => requests.get(`/api/users/${user.id}/work_orders/employer_work_orders_billing_paginated`, { offset, limit, sort }),
    register: (password, passwordConfirmation, token) => requests.put('/api/users/invitation.json', {
      api_user: {
        invitation_token: token,
        password,
        password_confirmation: passwordConfirmation,
      },
    }),
    resetPassword: (email) => requests.post('/api/users/password', {
      api_user: {
        email,
      },
      // TODO: There's some parameters missing, check what their function is and add if needed
      commit: 'Send me reset password instructions',
    }),
    changePassword: (token, password, passwordConfirmation) => requests.put('/api/users/password', {
      api_user: {
        reset_password_token: token,
        password,
        password_confirmation: passwordConfirmation,
      },
    }),
    purgeHelmetImage: (user) => requests.del(`/api/users/${user.id}/purge_helmet_image`),
    purgeTaxIncomeCard: (user) => requests.del(`/api/users/${user.id}/purge_taxation_income_card`),
    purgeCard: (user, card) => requests.del(`/api/users/${user.id}/purge/${card}`),
    purgeCardEmployer: (user, card) => requests.del(`/api/users/${user.id}/employer_purge/${card}`),
    workOrderTrips: (user) => requests.get(`/api/users/${user.id}/work_order_trips`),
    autocompleteLocations: (user) => requests.get(`/api/users/${user.id}/route_locations/autocomplete_locations`),
    autocompleteAccomodations: (user) => requests.get(`/api/users/${user.id}/work_orders/autocomplete_accomodations`),
    employers: () => requests.get('/api/users/employers'),
    getAll: () => requests.get('/api/users'),
    switchAccount: (user, accountId) => requests.post('/api/users/switch_account', {
      id: user.id,
      account_id: accountId,
    }),
    getWorkOrdersContext: (userId, workOrderIds) => requests.get(`/api/users/${userId}/work_order_context/?${workOrderIds.map((id, index) => (index !== workOrderIds.length - 1 ? `id[]=${id}&` : `id[]=${id}`))}`),
    getWorkOrderTripContext: (userId, workOrderTripId) => requests.get(`/api/users/${userId}/work_order_trip_context/${workOrderTripId}`),
  };

  const WorkOrders = {
    create: (workOrder) => requests.post('/api/work_orders', WorkOrder.toJson(workOrder)),
    update: (workOrder) => requests.put(`/api/work_orders/${workOrder.id}`, {
      work_order: WorkOrder.toJson(workOrder),
    }),
    deleteInvite: (invite) => requests.put(`/api/work_orders/${invite.workOrderId}`, {
      work_order: {
        id: invite.workOrderId,
        work_order_invitations_attributes: [{
          id: invite.id,
          user_id: invite.userId,
          _destroy: '1',
        }],
      },
    }),
    // Used in work order invitations (class EmployerWorkOrderDetail)
    getAvailabilities: (id, from, to, workPeriodId) => requests.get(`/api/work_orders${id ? `/${id}` : ''}/get_availabilities/${from}/${to}?work_period_id=${workPeriodId || null}`),
    // Used in the resource availability view (class ResourceAvailability)
    getAvailabilitiesMultipleWeeks: (id, from, to) => requests.get(`/api/work_orders${id ? `/${id}` : ''}/get_availabilities_multiple_weeks/${from}/${to}`),
    clone: (id) => requests.post(`/api/work_orders/${id}/clone`),
    getWorkOrderReportByUser: (id) => requests.get(`/api/work_orders/${id}/get_report_by_users`),
    getProductReport: (id) => requests.get(`/api/work_orders/${id}/product_report`),
    getWorkPeriods: (id) => requests.get(`/api/work_orders/${id}/work_periods`),
    delete: (id) => requests.del(`/api/work_orders/${id}`),
    getAllWorkOrderAttachments: (id) => requests.get(`/api/work_orders/${id}/get_all_attachments`),
  };

  const WorkOrderInvitations = {
    accept: (invitation) => {
      const { id } = invitation;
      return requests.post(`/api/work_order_invitations/${id}/accept`, {
        work_order_invitation: { more_info: invitation.more_info || '' },
      });
    },
    reject: (invitation) => {
      const { id } = invitation;
      return requests.post(`/api/work_order_invitations/${id}/reject`, {
        work_order_invitation: { more_info: invitation.more_info || '' },
      });
    },
    update: (invitation) => {
      const { id } = invitation;
      return requests.post(`/api/work_order_invitations/${id}/update`, {
        work_order_invitation: { can_participate: invitation.canParticipate },
      });
    },
  };

  const Availability = {
    del: (user) => requests.del(`/api/users/${user.id}/availabilities`),
    add: (user, availability) => requests.post(
      `/api/users/${user.id}/availabilities`,
      availability == null ? null : {
        availability: {
          from: availability.from.format('YYYY-MM-DD'),
          to: availability.to.format('YYYY-MM-DD'),
          available: availability.available,
        },
      },
    ),
  };

  const WorkOrderUserPrivileges = {
    del: (privilegeId) => requests.del(`/api/work_order_user_privileges/${privilegeId}`),
  };

  const Messages = {
    send: (room, message) => requests.post(`/api/chats/${room.id}/messages`, {
      message: {
        content: message.content,
        sent_at: message.sentAt,
        reply_to: message.replyTo,
      },
    }),
    del: (room, message) => requests.del(`/api/chats/${room.id}/messages/${message.id}`),
    findChats: (user) => requests.get(`/api/users/${user.id}/chats`),
    findChat: (user, id) => requests.get(`/api/users/${user.id}/chats/${id}`),
    unreadMessages: (user) => requests.get(`/api/users/${user.id}/unreadMessages`),
    markAsRead: (room, messageIds) => requests.post(`/api/chats/${room.id}/markAsRead`, {
      chat: { message_ids: messageIds || [] },
    }),
    sendSMS: (message) => requests.post(`/api/messages/${message.id}/smsdelivery`, {
      id: message.id,
      sms_delivery: true,
    }),
    getNewMessage: (chatId, messageId) => requests.post(`/api/messages/${messageId}/getNewMessage`, {
      chat_id: chatId,
    }),
    addEmailNotification: (chatId) => requests.post('/api/user_chats', {
      chat_id: chatId,
    }),
    deleteEmailNotification: (emailNotification) => requests.del(`/api/user_chats/${emailNotification.id}`),
  };

  const getTimeLogURL = (userId, workOrderId) => `/api/users/${userId}/work_orders/${workOrderId}/work_hours`;
  const getHourlyTimeLogURL = (userId, workOrderId, timeLogId) => `/api/users/${userId}/work_orders/${workOrderId}/work_hours/${timeLogId}/work_task_entries`;
  const TimeLogs = {
    getAll: (user, workOrder) => requests.get(getTimeLogURL(user.id, workOrder.id)),
    getEmployerHoursById: (user, ids) => requests.get(`/api/users/${user.id}/employer_hours_by_id?ids=${ids.join(',')}`),
    getEmployerHours: (user) => requests.get(`/api/users/${user.id}/employer_hours`),
    getEmployerHoursByDates: (user, from, to, salaryPeriodCategoryId, status = null) => requests.get(`/api/users/${user.id}/employer_hours_by_dates?from=${from}&to=${to}&salary_period_category_id=${salaryPeriodCategoryId}&status=${status}`),
    getEmployerTimelogReportByDates: (user, from, to) => requests.get(`/api/users/${user.id}/employer_timelog_report_by_dates?from=${from}&to=${to}`),
    getEmployerTimelogReportByDatesBatchStream: (user, from, to) => requests.get(`/api/users/${user.id}/employer_timelog_report_by_dates_batch_stream?from=${from}&to=${to}`),
    // eslint-disable-next-line no-unused-vars
    create: (timeLog, _) => requests.post(getTimeLogURL(timeLog.userId, timeLog.workOrderId), TimelogEntry.toJson(timeLog)),
    deleteWorkTaskEntry: (timeLog, workTaskEntry) => requests.del(`${getHourlyTimeLogURL(timeLog.userId, timeLog.workOrderId, timeLog.id)}/${workTaskEntry.id}`),
    update: (timeLog, employerContextTripId) => requests.put(
      `${getTimeLogURL(timeLog.userId, timeLog.workOrderId)}/${timeLog.id}`,
      {
        work_hour: TimelogEntry.toJson({
          ...timeLog,
          status: 'draft',
        }),
        context_trip_id: employerContextTripId,
      },
    ),
    accept: (workOrder, from, to) => requests.post(`${getTimeLogURL(workOrder.userId, workOrder.id)}/accept/${asDate(from, 'YYYY-MM-DD')}/${asDate(to, 'YYYY-MM-DD')}`),
    employerAccept: (user, workOrderId, timelogId) => requests.post(
      `/api/users/${user.id}/employer_accept/${timelogId}`,
      {
        work_order_id: workOrderId,
      },
    ),
    employerAcceptMultiple: (user, workHourData) => requests.post(
      `/api/users/${user.id}/employer_accept_multiple`,
      {
        work_hour_data: workHourData,
      },
    ),
    employerReject: (user, workOrderId, timelogId, employerComment, sendSMS = false) => requests.post(
      `/api/users/${user.id}/employer_reject/${timelogId}`,
      {
        work_order_id: workOrderId,
        employer_comment: employerComment,
        send_sms: sendSMS,
      },
    ),
    employerRejectEmpty: (user, workHour, employerComment, sendSMS = false) => requests.post(
      `/api/users/${user.id}/employer_reject/`,
      {
        work_hour: workHour,
        work_order_id: workHour.work_order_id,
        employer_comment: employerComment,
        send_sms: sendSMS,
      },
    ),
    employerRejectMultiple: (user, workHourData, employerComment, sendSMS = false) => requests.post(
      `/api/users/${user.id}/employer_reject_multiple/`,
      {
        // work_hour_ids: timelogIds,
        work_hour_data: workHourData,
        employer_comment: employerComment,
        send_sms: sendSMS,
      },
    ),
    sendToEmployers: (workOrder, from, to) => requests.post(`${getTimeLogURL(workOrder.userId, workOrder.id)}/send_to_employers/${asDate(from, 'YYYY-MM-DD')}/${asDate(to, 'YYYY-MM-DD')}`),
    del: (user, timeLog) => requests.del(`${getTimeLogURL(user.id, timeLog.workOrderId)}/${timeLog.id}`),
    // autofillAllowances: (user, startTripDate, startTripTime, endTripDate, endTripTime) => requests.post(`api/users/${user.id}/autofill_allowances`, {
    //   start_trip_date: startTripDate,
    //   start_trip_time: startTripTime,
    //   end_trip_date: endTripDate,
    //   end_trip_time: endTripTime,
    // }),
    // getWorkHourReport: (from, to) => requests.get('/api/work_hours/work_hour_report', { from, to }),
    employerUpdate: (workOrder, workHours, contextTripId) => requests.post(
      `${getTimeLogURL(workOrder.userId, workOrder.id)}/employer_update`,
      {
        work_hours: workHours,
        context_trip_id: contextTripId,
      },
    ),
    employerUpdateAndAccept: (workOrder, workHours, contextTripId) => requests.post(
      `${getTimeLogURL(workOrder.userId, workOrder.id)}/employer_update_and_accept`,
      {
        work_hours: workHours,
        context_trip_id: contextTripId,
      },
    ),
    // getEmployerHoursById: (user, ids) => requests.get(`/api/users/${user.id}/employer_hours_by_id?ids=${ids.join(',')}`),
    getWorkHoursByIds: (user, ids) => requests.get(
      `/api/users/${user.id}/work_hours_by_ids?${ids.map((id, index) => (index !== ids.length - 1 ? `id[]=${id}&` : `id[]=${id}`))}`,
      // `/api/users/${user.id}/work_hours_by_ids?ids=${ids.join(',')}`,
    ),
    missingWorkOrderTripReject: (workHourId, employerComment, sendSMS) => requests.post(
      `/api/work_hours/${workHourId}/reject_missing_work_order_trip`,
      {
        employer_comment: employerComment,
        send_sms: sendSMS,
      },
    ),
  };

  // const Purchasers = {
  //   getAll: (user) => requests.get(`/api/users/${user.id}/purchasers`),
  // };

  const Purchasers = {
    getAll: () => requests.get('/api/purchasers'),
    create: (purchaser) => requests.post('/api/purchasers/', { purchaser: Purchaser.toJson(purchaser) }),
    update: (purchaser) => requests.put(`/api/purchasers/${purchaser.id}`, { purchaser: Purchaser.toJson(purchaser) }),
  };

  const Locations = {
    getAll: () => requests.get('/api/locations'),
    create: (location) => requests.post('/api/locations/', { location: Location.toJson(location) }),
    update: (location) => requests.put(`/api/locations/${location.id}`, { location: Location.toJson(location) }),
  };

  const WorkTasks = {
    create: (workOrderId, workTask) => requests.post(`/api/work_orders/${workOrderId}/work_tasks/`, { work_task: WorkTask.toJson(workTask) }),
    update: (workOrderId, workTask) => requests.put(`/api/work_orders/${workOrderId}/work_tasks/${workTask.id}`, { work_task: WorkTask.toJson(workTask) }),
    purgeFile: (workOrderId, workTaskId, fileSignedId) => requests.del(`/api/work_orders/${workOrderId}/work_tasks/${workTaskId}/purge_file/${fileSignedId}`),
    generatePDF: (workOrderId, workTaskId, signedIds) => requests.post(
      `/api/work_orders/${workOrderId}/work_tasks/${workTaskId}/generate_pdf`,
      {
        signed_ids: signedIds,
      },
    ),
    del: (workOrderId, workTaskId) => requests.del(`/api/work_orders/${workOrderId}/work_tasks/${workTaskId}`),
    getByWorkOrder: (workOrderId) => requests.get(`/api/work_orders/${workOrderId}/work_order_work_tasks`),
    // generatePDF: (attachmentId, signedIds) => requests.post(
    //   '/api/billing_attachments/pdf',
    //   {
    //     attachment_id: attachmentId,
    //     signed_ids: signedIds,
    //   },
    // ),
  };

  // DEACTIVATED PRODUCTS
  const Products = {
    getAll: () => requests.get('/api/products'),
    create: (product) => requests.post('/api/products', Product.toJson(product)),
    update: (product) => requests.put(`/api/products/${product.id}`, Product.toJson(product)),
  };

  // DEACTIVATED PRODUCTS
  const UserProductCollections = {
    getAll: (user) => requests.get(`/api/users/${user.id}/user_product_collections`),
    create: (user, userProductCollection) => requests.post(`/api/users/${user.id}/user_product_collections`, UserProductCollection.toJson(userProductCollection)),
    update: (user, userProductCollection) => requests.put(
      `/api/users/${user.id}/user_product_collections/${userProductCollection.id}`,
      UserProductCollection.toJson({
        ...userProductCollection,
        // status: 'draft',
      }),
    ),
    purgeFile: (user, userProductCollectionId, signedId) => requests.del(`/api/users/${user.id}/user_product_collections/${userProductCollectionId}/purge_file/${signedId}`),
  };

  const WorkOrderTrips = {
    create: (workOrderTrip, currentWorkOrderId) => requests.post(
      '/api/work_order_trips',
      {
        work_order_trip: WorkOrderTrip.toJson(workOrderTrip),
        current_work_order_id: currentWorkOrderId,
      },
    ),
    update: (workOrderTrip, currentWorkOrderId) => requests.put(
      `/api/work_order_trips/${workOrderTrip.id}`,
      {
        work_order_trip: WorkOrderTrip.toJson({
          ...workOrderTrip,
          status: 'draft',
        }),
        current_work_order_id: currentWorkOrderId,
      },
    ),
    del: (workOrderTrip) => requests.del(`/api/work_order_trips/${workOrderTrip.id}`),
    // accept: (tripRoute) => requests.post(`/api/trip_routes/${tripRoute.id}/accept`),
    sendToEmployers: (workOrderTrip, mode, currentWorkOrderId) => requests.post(`/api/work_order_trips/send_to_employers/${mode}`,
      {
        work_order_trip: WorkOrderTrip.toJson(workOrderTrip),
        current_work_order_id: currentWorkOrderId,
      }),
    employerSaveAndAccept: (workOrderTrip, mode, currentWorkOrderId) => requests.post('/api/work_order_trips/employer_save_and_accept',
      {
        work_order_trip: WorkOrderTrip.toJson(workOrderTrip),
        current_work_order_id: currentWorkOrderId,
      }),
    getEmployerWorkOrderTrips: (user) => requests.get(`/api/users/${user.id}/employer_work_order_trips`),
    getEmployerWorkOrderTripsById: (user, id) => requests.get(`/api/users/${user.id}/employer_work_order_trips/${id}`),
    employerAccept: (tripId, expenseIds, tripRouteIds) => requests.post(
      '/api/work_order_trips/employer_accept',
      {
        work_order_trip_id: tripId,
        tax_exempt_trip_expense_ids: expenseIds,
        trip_route_ids: tripRouteIds,
      },
    ),
    employerAcceptTripExpenses: (expenseIds, tripRouteIds) => requests.post(
      '/api/work_order_trips/employer_accept_trip_expenses',
      {
        tax_exempt_trip_expense_ids: expenseIds,
        trip_route_ids: tripRouteIds,
      },
    ),
    getKmsAutofillByLocations: (departureLoc, destinationLoc) => requests.get(`/api/work_order_trips/get_kms_autofill_by_locations?departure_street=${departureLoc.street}&departure_city=${departureLoc.city}&departure_zip_code=${departureLoc.zipCode}&destination_street=${destinationLoc.street}&destination_city=${destinationLoc.city}&destination_zip_code=${destinationLoc.zipCode}`),
    // getEmployerWorkOrderTrip: (user, id) => requests.get(`/api/users/${user.id}/employerWorkOrderTrips/${id}`)
    // getAllEmployeeTrips: (from, to) => requests.get(`/api/work_order_trips/getAllEmployeeTrips/${from}/${to}`),
  };

  const StartTrips = {
    create: (startTrip) => requests.post('/api/start_trips', StartTrip.toJson(startTrip)),
    update: (startTrip) => requests.put(
      `/api/start_trips/${startTrip.id}`,
      {
        start_trip: StartTrip.toJson({
          ...startTrip,
          status: 'draft',
        }),
      },
    ),
    del: (startTrip) => requests.del(`/api/start_trips/${startTrip.id}`),
    // accept: (id) => requests.post(`/api/start_trips/${id}/accept`),
    employerAccept: (startTripId) => requests.post(
      `/api/start_trips/${startTripId}/employer_accept`,
    ),
    employerReject: (startTripId, employerComment, sendSMS = false) => requests.post(
      `/api/start_trips/${startTripId}/employer_reject/`,
      {
        employer_comment: employerComment,
        send_sms: sendSMS,
      },
    ),
  };

  const EndTrips = {
    create: (endTrip) => requests.post('/api/end_trips', EndTrip.toJson(endTrip)),
    update: (endTrip) => requests.put(
      `/api/end_trips/${endTrip.id}`,
      {
        end_trip: EndTrip.toJson({
          ...endTrip,
          status: 'draft',
        }),
      },
    ),
    del: (endTrip) => requests.del(`/api/end_trips/${endTrip.id}`),
    employerAccept: (endTripId) => requests.post(
      `/api/end_trips/${endTripId}/employer_accept`,
    ),
    employerReject: (endTripId, employerComment, sendSMS = false) => requests.post(
      `/api/end_trips/${endTripId}/employer_reject/`,
      {
        employer_comment: employerComment,
        send_sms: sendSMS,
      },
    ),
  };

  // const getTripRouteURL = (userId, workOrderId) => `/api/users/${userId}/work_orders/${workOrderId}/work_order_trips`;
  const TripRoutes = {
    create: (tripRoute) => requests.post('/api/trip_routes', TripRoute.toJson(tripRoute)),
    update: (tripRoute) => requests.put(
      `/api/trip_routes/${tripRoute.id}`,
      {
        trip_route: TripRoute.toJson({
          ...tripRoute,
          status: 'draft',
        }),
      },
    ),
    del: (tripRoute) => requests.del(`/api/trip_routes/${tripRoute.id}`),
    employerReject: (tripRoutes, sendSMS) => requests.post(
      '/api/trip_routes/reject',
      {
        trip_routes: tripRoutes,
        send_sms: sendSMS,
      },
    ),
  };

  const RouteLocations = {
    create: (routeLocation) => requests.post('/api/route_locations', RouteLocation.toJson(routeLocation)), // TO JSON HERE?
    update: (routeLocation) => requests.put(
      `/api/route_locations/${routeLocation.id}`,
      {
        route_location: RouteLocation.toJson({
          ...routeLocation,
          status: 'draft',
        }),
      },
    ),
    del: (routeLocation) => requests.del(`/api/route_locations/${routeLocation.id}`),
  };

  const SalaryPeriods = {
    getAll: () => requests.get('/api/salary_periods'),
    getCurrent: () => requests.get('/api/salary_periods/current'),
    getClosedGroupedByCategory: () => requests.get('/api/salary_periods/closed_and_grouped_by_category'),
    lock: (salaryPeriodId) => requests.post(`/api/salary_periods/${salaryPeriodId}/lock`),
    close: (salaryPeriodId) => requests.post(`/api/salary_periods/${salaryPeriodId}/close`),
    updateMessage: (salaryPeriodId, messageContent) => requests.post(`/api/salary_periods/${salaryPeriodId}/update_message`, { content: messageContent }),
    downloadPayrollAdminReportsZip: (salaryPeriodId) => requests.getFile(`/api/salary_periods/${salaryPeriodId}/download_payroll_admin_reports_zip`),
  };

  const SalaryPeriodAttachments = {
    create: (data) => requests.post(
      '/api/salary_period_attachments',
      {
        file: data.file,
        salary_period_id: data.periodId,
        uploader_id: data.uploaderId,
        user_id: data.userId,
        work_order_id: data.workOrderId,
      },
    ),
    del: (id) => requests.del(`/api/salary_period_attachments/${id}`),
    getAll: () => requests.get('/api/salary_period_attachments'),
  };

  const WorkOrderAttachments = {
    create: (data) => requests.post(
      '/api/work_order_attachments',
      {
        file: data.file,
        description: data.description,
        title: data.title,
        work_order_id: data.workOrderId,
      },
    ),
    update: (data) => requests.put(
      `/api/work_order_attachments/${data.id}`,
      {
        file: data.file,
        description: data.description,
        title: data.title,
        work_order_id: data.workOrderId,
      },
    ),
    del: (id) => requests.del(`/api/work_order_attachments/${id}`),
    getAll: () => requests.get('/api/work_order_attachments'),
    purgeAttachmentFile: (id) => requests.del(`/api/work_order_attachments/${id}/purge_attachment_file`),
  };

  const BillingAttachments = {
    create: (data) => requests.post(
      '/api/billing_attachments',
      {
        billing_attachment: BillingAttachment.toJson(data),
      },
    ),
    update: (data) => requests.put(
      `/api/billing_attachments/${data.id}`,
      {
        billing_attachment: BillingAttachment.toJson(data),
      },
    ),
    del: (id) => requests.del(`/api/billing_attachments/${id}`),
    getAll: () => requests.get('/api/billing_attachments'),
    purgeAttachmentFile: (id, signedId) => requests.del(`/api/billing_attachments/${id}/purge_attachment_file/${signedId}`),
    employerAccept: (id) => requests.post(
      `/api/billing_attachments/${id}/employer_accept`,
    ),
    generatePDF: (attachmentId, signedIds) => requests.post(
      '/api/billing_attachments/pdf',
      {
        attachment_id: attachmentId,
        signed_ids: signedIds,
      },
    ),
  };

  const WorkOrderBillings = {
    create: (data) => requests.post(
      '/api/bills',
      { bill: data },
    ),
    update: (data) => requests.put(
      `/api/bills/${data.id}`,
      { bill: data },
    ),
  };

  const CloseCallReports = {
    send: (data) => requests.post(
      '/api/close_call_reports',
      {
        close_call_report: CloseCallReport.toJson(data),
      },
    ),
    respond: (id, response) => requests.post(
      `/api/close_call_reports/${id}/respond`,
      {
        response,
      },
    ),
    get: () => requests.get('/api/close_call_reports'),
    // close_call_report instead of close_call_reports here is to prevent rails automagically trying to render with show, which fails, because there's more than one report
    getEmployer: () => requests.get('/api/close_call_report/employer'),
  };

  const AccidentReports = {
    create: (data) => requests.post(
      '/api/accident_reports',
      {
        accident_report: AccidentReport.toJson(data),
      },
    ),
    get: () => requests.get('/api/accident_reports'),
    update: (data) => requests.put(
      `/api/accident_reports/${data.id}`,
      {
        accident_report: AccidentReport.toJson(data),
      },
    ),
  };

  const Validities = {
    create: (data) => requests.postFormData(
      '/api/validities',
      Validity.toJson(data),
      // {
      //   validity: Validity.toJson(data),
      //   // name: data.name,
      //   // number: data.number,
      //   // description: data.description,
      //   // valid_through_date: data.validThroughDate,
      //   // admittance_date: data.admittanceDate,
      //   // work_order_id: data.workOrderId,
      //   // company_id: data.companyId,
      //   // location_id: data.locationId,
      // },
    ),
    update: (data) => requests.putFormData(
      `/api/validities/${data.id}`,
      Validity.toJson(data),
      // {
      //   validity: Validity.toJson(data),
      //   // name: data.name,
      //   // number: data.number,
      //   // description: data.description,
      //   // valid_through_date: data.validThroughDate,
      //   // admittance_date: data.admittanceDate,
      //   // work_order_id: data.workOrderId,
      //   // company_id: data.companyId,
      //   // location_id: data.locationId,
      // },
    ),
    del: (data) => requests.del(`/api/validities/${data.id}`),
    getAll: () => requests.get('/api/validities'),
    purgeFile: (data, signedId) => requests.del(`/api/validities/${data.id}/purge_file/${signedId}`),
  };

  const AllowanceAutofillReminders = {
    // enabled is assumed to be always false with the current implementation
    create: (salaryPeriodId, workOrderId, enabled) => requests.post('/api/allowance_autofill_reminders/', {
      salary_period_id: salaryPeriodId,
      work_order_id: workOrderId,
      enabled,
    }),
  };

  const TaxExemptTripExpenses = {
    employerReject: (expenses, sendSMS) => requests.post(
      '/api/tax_exempt_trip_expenses/reject',
      {
        tax_exempt_trip_expenses: expenses,
        send_sms: sendSMS,
      },
    ),
  };

  const Certificates = {
    getAll: () => requests.get('/api/certificates'),
    create: (certificate) => requests.post('/api/certificates', { certificate: Certificate.toJson(certificate) }),
    update: (certificate) => requests.put(`/api/certificates/${certificate.id}`, { certificate: Certificate.toJson(certificate) }),
  };

  const CertificateTypes = {
    getAll: () => requests.get('/api/certificate_types'),
    create: (certificateType) => requests.post('/api/certificate_types', { certificate_type: CertificateType.toJson(certificateType) }),
    update: (certificateType) => requests.put(`/api/certificate_types/${certificateType.id}`, { certificate_type: CertificateType.toJson(certificateType) }),
  };

  const AbsencePeriods = {
    create: (absencePeriod) => requests.post('/api/absence_periods', { absence_period: AbsencePeriod.toJson(absencePeriod) }),
    employerCreate: (absencePeriod) => requests.post('/api/absence_periods/employer_create', { absence_period: AbsencePeriod.toJson(absencePeriod) }),
    update: (absencePeriod) => requests.put(`/api/absence_periods/${absencePeriod.id}`, { absence_period: AbsencePeriod.toJson(absencePeriod) }),
    del: (absencePeriodId) => requests.del(`/api/absence_periods/${absencePeriodId}`),
    employeeAccept: (absencePeriodId) => requests.post(`/api/absence_periods/${absencePeriodId}/employee_accept`),
  };

  const AbsenceEntries = {
    getEmployerAbsenceEntries: (user) => requests.get(`/api/users/${user.id}/employer_absence_entries`),
    employerAcceptMultipleEntries: (user, absenceEntryData) => requests.post(
      `/api/users/${user.id}/employer_absence_entries/employer_accept_multiple`,
      {
        absence_entry_data: absenceEntryData,
      },
    ),
    getEmployerAbsenceEntriesBySalaryPeriod: (user, from, to, salaryPeriodCategoryId) => requests.get(`/api/users/${user.id}/employer_absence_entries_by_dates?from=${from}&to=${to}&salary_period_category_id=${salaryPeriodCategoryId}`),
    update: (absenceEntry) => requests.put(`/api/absence_entries/${absenceEntry.id}`, { absence_entry: AbsenceEntry.toJson(absenceEntry) }),
    del: (absenceEntry) => requests.del(`/api/absence_entries/${absenceEntry.id}`),
  };

  const BulletinBoardPosts = {
    create: (data) => requests.postFormData('/api/bulletin_board_posts', BulletinBoardPost.toJson(data)),
    update: (data) => requests.putFormData(`/api/bulletin_board_posts/${data.id}`, BulletinBoardPost.toJson(data)),
    del: (id) => requests.del(`/api/bulletin_board_posts/${id}`),
    get: () => requests.get('/api/bulletin_board_posts'),
    getAll: () => requests.get('/api/bulletin_board_posts/get_all'),
    getByCategory: (categoryId) => requests.get(`/api/bulletin_board_posts/get_by_category/${categoryId}`),
    purgeFile: (data, signedId) => requests.del(`/api/bulletin_board_posts/${data.id}/purge_file/${signedId}`),
  };

  const BulletinBoardCategories = {
    get: () => requests.get('/api/bulletin_board_categories'),
    create: (data) => requests.post('/api/bulletin_board_categories', BulletinBoardCategory.toJson(data)),
    update: (data) => requests.put(`/api/bulletin_board_categories/${data.id}`, {
      bulletin_board_category: BulletinBoardCategory.toJson(data),
    }),
    del: (id) => requests.del(`/api/bulletin_board_categories/${id}`),
    // get: () => requests.get('/api/bulletin_board_categories'),
    getAll: () => requests.get('/api/bulletin_board_categories/get_all'),
  };

  return {
    Availability,
    Messages,
    SalaryPeriodAttachments,
    SalaryPeriods,
    TimeLogs,
    WorkOrderTrips,
    StartTrips,
    EndTrips,
    TripRoutes,
    RouteLocations,
    Users,
    WorkOrderInvitations,
    Purchasers,
    Locations,
    WorkOrders,
    WorkOrderAttachments,
    WorkTasks,
    BillingAttachments,
    WorkOrderBillings,
    CloseCallReports,
    AccidentReports,
    // DEACTIVATED PRODUCTS
    UserProductCollections,
    Products,
    Validities,
    WorkOrderUserPrivileges,
    AllowanceAutofillReminders,
    TaxExemptTripExpenses,
    Certificates,
    CertificateTypes,
    AbsencePeriods,
    AbsenceEntries,
    BulletinBoardPosts,
    BulletinBoardCategories,
  };
};
