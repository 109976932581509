import { observable, action } from 'mobx';
// import _ from 'lodash';
import moment from '../utils/moment';
import User from '../models/User';
import WorkOrder from '../calendar/WorkOrder';
import WorkOrderTrip from '../timelog/WorkOrderTrip';
import AutocompleteLocation from '../timelog/AutocompleteLocation';
// import BulletinBoardPost from '../models/BulletinBoardPost';
import BulletinBoardCategory from '../models/BulletinBoardCategory';

// const defaultOffset = 0;
// const defaultLimit = 3;
const defaultArchivedLimit = 5;
const defaultArchivedOffset = 0;

export default class LoginStore {
  @observable isLoggingIn = false;

  @observable loginFailed = false;

  @observable shakeLoginButton = false;

  @observable feedbackText = '';

  @observable emailValue = '';

  @observable passwordValue = '';

  @observable isLoggedIn = true;

  @observable isChangingPassword = false;

  // This is used to limit the amount of archived work orders that are queried
  @observable limit = defaultArchivedLimit;

  // // Every time the archived work orders are queried, this is increased by the limit to form the pagination in the queries
  // @observable offset = defaultOffset;

  @observable archivedLimit = defaultArchivedLimit;

  // Since the user is going to query the recently ended work orders during login, we start with offset 3 to prevent duplicate archived wos
  @observable archivedOffset = defaultArchivedOffset;

  // Used to disable the button that triggers findArchivedWorkOrders() for pagination (timelog/index.js + calendar/WorkOrderList.jsx)
  @observable getArchivedWorkOrdersDisabled = false;

  constructor(uiStore, productStore, requests, errorStore, actionCableStore, timelogStore, workOrderTripStore, afterLogin, employerWorkOrderStore) {
    this.uiStore = uiStore;
    this.productStore = productStore;
    this.afterLogin = afterLogin;
    this.requests = requests;
    this.errorStore = errorStore;
    this.actionCableStore = actionCableStore;
    this.employerWorkOrderStore = employerWorkOrderStore;
    this.timelogStore = timelogStore;
    this.workOrderTripStore = workOrderTripStore;
  }

  get hasToken() {
    return this.isLoggedIn;
  }

  @action logout() {
    this.actionCableStore.disconnectConsumer();
    this.requests.Users.logout().then(() => {
      this.isLoggedIn = false;
      this.uiStore.currentUser = null;
      // this.offset = defaultOffset;
      this.archivedOffset = defaultArchivedOffset;
      this.getArchivedWorkOrdersDisabled = false;
      this.uiStore.setEmployerMode(false);
      // TODO: This breaks the registration because opening the password creation from the email registration link calls this
      // Redirecting to calendar because it acts as the landing page + prevents the awkward white background login screens if logged out in an employer view
      // this.uiStore.showCalendar();
      this.timelogStore.unsubscribe();
      this.workOrderTripStore.unsubscribe();
      this.isLoggingIn = false;
      this.employerWorkOrderStore.emptyCaches();
    });
  }

  @action switchAccount(accountId, afterLogin) {
    const { currentUser } = this.uiStore;

    if (accountId === currentUser.currentAccount.account_id) {
      return;
    }

    this.requests.Users.switchAccount(currentUser, accountId).then(() => {
      // If we don't use refresh mode (second var) here, the past work orders aren't updated properly
      this.findMe(null, true)
        .then((user) => {
          afterLogin(user);
          this.isLoggingIn = false;
        })
        .catch(() => {
          this.isLoggingIn = false;
        });
    });
  }

  @action updateEmail = (event) => {
    this.emailValue = event.target.value;
  };

  @action updatePassword = (event) => {
    this.passwordValue = event.target.value;
  };

  @action resetPassword = () => {
    this.feedbackText = '';
    if (!this.emailValue) {
      this.feedbackText = 'Syötä sähköposti.';
    } else {
      this.requests.Users.resetPassword(this.emailValue)
        .then(() => {
          this.feedbackText = 'Ohjeet salasanan vaihtamiseen on lähetetty sähköpostiisi.';
        });
    }
  };

  @action changePassword = async (token, password, passwordConfirmation) => {
    this.feedbackText = ' ';
    this.isChangingPassword = true;

    if (!password || !passwordConfirmation) {
      this.feedbackText = 'Syötä salasana ja vahvista se.';
      this.isChangingPassword = false;
    } else if (password !== passwordConfirmation) {
      this.feedbackText = 'Salasanat eivät täsmää.';
      this.isChangingPassword = false;
    } else {
      try {
        await this.requests.Users.changePassword(token, password, passwordConfirmation);
        // Update the URL with the pwreset=success query parameter
        const newUrl = '/app/calendar?pwreset=success';
        window.history.pushState(null, '', newUrl);
        // Navigate to the calendar view
        this.uiStore.showCalendar();
      } catch (err) {
        if (err.response && err.response.data && err.response.data.errors) {
          const errorMessages = Object.values(err.response.data.errors).flat();
          this.feedbackText = errorMessages.join(', ');
        } else {
          this.feedbackText = 'Salasanan vaihto epäonnistui. Yritä uudelleen.';
        }
      } finally {
        this.isChangingPassword = false;
      }
    }
  };

  findWorkOrders = (user, refreshing = false) => {
    const ctxUser = user || this.uiStore.currentUser;
    let queryLimit = this.limit;
    let queryOffset = this.archivedOffset;

    // We don't want refresh to only find the next ended work orders, do a full refresh with current parameters instead
    if (refreshing) {
      queryLimit = this.archivedOffset > defaultArchivedLimit ? this.archivedOffset : defaultArchivedLimit;
      queryOffset = 0;
    }

    return this.requests.Users.workOrders(ctxUser, queryOffset, queryLimit)
      .then(
        action((json) => {
          const workOrders = json.map((wo) => {
            this.newAccomodationForAutocomplete(wo, ctxUser);
            return WorkOrder.fromJsonProperties(wo);
          });

          if (!refreshing) {
            // Update the offset for future queries
            this.archivedOffset += queryLimit;
          }

          const now = moment().startOf('day');

          const onGoing = workOrders.filter((wo) => now.isBetween(wo.interval.start, wo.interval.end, 'day', '[]'));
          const notOnGoing = workOrders.filter((wo) => !now.isBetween(wo.interval.start, wo.interval.end, 'day', '[]'));
          const future = notOnGoing.filter((wo) => wo.interval.start.valueOf() > now.valueOf());
          const past = notOnGoing.filter((wo) => wo.interval.end.valueOf() < now.valueOf());

          future.sort((a, b) => {
            // Work orders starting earlier should go first because they're more relevant
            if (a.interval.start.valueOf() < b.interval.start.valueOf()) {
              return -1;
            }
            return 1;
          });

          // Used to add a margin-top 1em to the work order elements to visually separate categories
          if (future.length > 0) {
            future[0]._addGap = true;
          }

          if (past.length > 0) {
            past[0]._addGap = true;
          }

          if (past.length < this.archivedLimit) {
            this.getArchivedWorkOrdersDisabled = true;
          }

          ctxUser.workOrders.replace([
            ...onGoing,
            ...future,
            ...past,
          ]);
        }),
      )
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  findArchivedWorkOrders = (user, refreshing = false) => {
    const { updateForWorkOrder } = this.timelogStore;
    const ctxUser = user || this.uiStore.currentUser;
    let queryLimit = this.archivedLimit;
    let queryOffset = this.archivedOffset;

    // We don't want refresh to only find the next ended work orders, do a full refresh with current parameters instead
    if (refreshing) {
      queryLimit = this.archivedOffset > defaultArchivedLimit ? this.archivedOffset : defaultArchivedLimit;
      queryOffset = 0;
    }

    return this.requests.Users.archivedWorkOrders(ctxUser, queryOffset, queryLimit)
      .then(
        action((response) => {
          if (response.length < this.archivedLimit) {
            // Disable searching for more archived work orders if no more can be found (query results empty set or below the limit)
            this.getArchivedWorkOrdersDisabled = true;
          }

          const tempWorkOrders = [...ctxUser.workOrders];
          const workOrders = response.map((wo) => {
            const woObj = WorkOrder.fromJsonProperties(wo);
            // A stupid hack to fix the fact that both relevant (current + future) work orders and archived work orders are in the same array
            // If they were in separate arrays we can just replace the archived array here, without overwriting the relevant work orders
            const foundOldWo = ctxUser.workOrders.find((oldWo) => oldWo.id === wo.id);
            if (!foundOldWo) {
              tempWorkOrders.push(woObj);
            }

            return woObj;
          });

          ctxUser.workOrders.replace(tempWorkOrders);
          // We push every time since ctxUser.workOrders should always have the relevant work orders already there in a "clean" state, no need to reset the object here
          // See this.findWorkOrders()

          // We want to skip updating workOrdersWithMeta if we're in the calendar view and there's no value to update yet
          // The value is set when first navigating to the timelog views (work orders are organized using queried salary periods)
          if (this.timelogStore.workOrdersWithMeta?.value?.archived) {
            let packagedWorkOrders = null;
            // We need to "package" the work orders into meta objects so that they can be consumed by timelog/index.js renderWorkOrderView()
            // Passing the function as a parameter here as a hack due to missing return statement in updateForWorkOrder()
            updateForWorkOrder(workOrders, (result) => {
              packagedWorkOrders = result;
            });

            // TODO: This doesn't actually trigger a re-render in timelog/index.js: the use of Promise like this is likely fundamentally flawed
            // Instead, as a hack, we use updating this.archivedOffset in timelog/index.js to render the new archived work orders
            // Using promise directly in the state is problematic, here we basically update another query's promise's results
            // We should really use the promise's result in the state instead
            this.timelogStore.workOrdersWithMeta.value.archived = this.timelogStore.workOrdersWithMeta.value.archived.concat(packagedWorkOrders.archived);
          }

          if (!refreshing) {
            // Do not update offset if no work orders were found to prevent problems when a work order is unretrievable
            if (workOrders.length > 0) {
              this.archivedOffset += this.archivedLimit;
            }
          }
        }),
      )
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  findWorkOrderTrips = (user) => {
    const ctxUser = user || this.uiStore.currentUser;

    return this.requests.Users.workOrderTrips(ctxUser)
      .then(
        action((json) => {
          const workOrderTrips = json.map((trip) => WorkOrderTrip.fromJsonProperties(trip));

          ctxUser.workOrderTrips.replace([
            ...workOrderTrips,
          ]);
        }),
      )
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  findUniqLocations = (user) => {
    const ctxUser = user || this.uiStore.currentUser;

    return this.requests.Users.autocompleteLocations(ctxUser)
      .then(
        action((json) => {
          // const uniqLocations = json.map((location) => AutocompleteLocation.fromJsonProperties(location));
          const uniqLocations = [];

          const homeLocation = AutocompleteLocation.fromJsonProperties({
            street: ctxUser.street,
            zip_code: ctxUser.zipCode,
            city: ctxUser.city,
            name: 'Koti',
          });

          if (homeLocation.street && homeLocation.city) {
            uniqLocations.push(homeLocation);
          }

          json.forEach((locationJson) => {
            const location = AutocompleteLocation.fromJsonProperties(locationJson);

            // Find find already pushed locations where:
            // street and zip codes are same, new location has a name when old location doesn't (replace with the named version)
            // OR street and zip codes are same (no-op, replace with identical)
            const oldLocationIndex = uniqLocations.findIndex((obj) => obj.street === location.street && obj.city === location.city);

            if (oldLocationIndex === -1) {
              // Add
              uniqLocations.push(location);
            } else if ((location.zipCode && !uniqLocations[oldLocationIndex].zipCode) || (location.name && !uniqLocations[oldLocationIndex].name)) {
              // Replace if the new location has more info (zipCode or name) than the old one
              uniqLocations[oldLocationIndex] = location;
            }
          });

          ctxUser.autocompleteLocations.replace([
            ...uniqLocations,
          ]);
        }),
      )
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  getBulletinBoardCategories = (user) => {
    const ctxUser = user || this.uiStore.currentUser;

    return this.requests.BulletinBoardCategories.get()
      .then(
        action((json) => {
          const bulletinBoardCategories = json.map(BulletinBoardCategory.fromJsonProperties);

          ctxUser.bulletinBoardCategories.replace([
            ...bulletinBoardCategories,
          ]);
        }),
      )
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  getAutocompleteAccomodations = (user) => {
    const ctxUser = user || this.uiStore.currentUser;

    return this.requests.Users.autocompleteAccomodations(ctxUser)
      .then(
        action((json) => {
          const uniqLocations = json.map((accomodation) => AutocompleteLocation.fromJsonProperties({
            name: accomodation.accomodation_name,
            street: accomodation.accomodation_street,
            city: accomodation.accomodation_city,
            zip_code: accomodation.accomodation_zip_code,
          }));
          return uniqLocations;
        }),
      )
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  newAccomodationForAutocomplete(workOrder, user) {
    const newAccomodation = {
      street: workOrder.accomodation_street,
      zipCode: workOrder.accomodation_zip_code,
      city: workOrder.accomodation_city,
      name: workOrder.accomodation_name,
    };

    this.newAutocompleteAddress(newAccomodation, user);
  }

  newAutocompleteAddress(newAddressParam, user = null) {
    const ctxUser = user || this.uiStore.currentUser;
    const newAddress = AutocompleteLocation.fromJsonProperties({
      street: newAddressParam.street,
      zip_code: newAddressParam.zipCode,
      city: newAddressParam.city,
      name: newAddressParam.name,
    });

    if (newAddress.street && newAddress.city) {
      const foundIndex = ctxUser.autocompleteLocations.findIndex((address) => address.street === newAddress.street && address.city === newAddress.city);
      // Match found, check if should be replaced or not
      if (foundIndex !== -1) {
        const foundAddress = ctxUser.autocompleteLocations[foundIndex];
        // Replace the old address without a zip code if the new one has it
        if (!foundAddress.zipCode && newAddress.zipCode) {
          ctxUser.autocompleteLocations[foundIndex] = newAddress;
        } else if ((foundAddress.zipCode === newAddress.zipCode) && !foundAddress.name && newAddress.name) {
          ctxUser.autocompleteLocations[foundIndex] = newAddress;
        }
      // No match found, push to the autocompleteLocations
      } else {
        ctxUser.autocompleteLocations.push(newAddress);
      }
    }
  }

  getSalaryPeriods = (user) => {
    const ctxUser = user || this.uiStore.currentUser;

    return this.requests.SalaryPeriods.getAll()
      .then(
        action((json) => {
          // ctxUser.salaryPeriods = json;
          ctxUser.setSalaryPeriods(json);
        }),
      )
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  @action findMe = async (cb = null, refreshing = null) => {
    try {
      const userJSON = await this.requests.Users.current();

      return action(async () => {
        const user = User.fromJsonProperties(userJSON);

        await this.findWorkOrders(user, refreshing);
        if (refreshing) {
          // We need to reset workOrdersWithMeta upon refresh so that we don't get duplicate work orders if they refresh in the archived timelog view
          await this.timelogStore.getSalaryPeriods(user.workOrders);
        }
        if (refreshing && this.archivedOffset !== defaultArchivedOffset) {
          // If any archived work orders have been loaded, refresh them too
          // findWorkers() resets user.workOrders array and we then add these archived work orders to the same array
          await this.findArchivedWorkOrders(user, refreshing);
        }
        await this.getSalaryPeriods(user);
        await this.findWorkOrderTrips(user);
        await this.findUniqLocations(user);

        if (user.accountInfo.bulletinBoardEnabled) {
          // await this.getBulletinBoardPosts(user);
          await this.getBulletinBoardCategories(user);
        }
        // This is a problem if we want to prevent data from flashing in and out when calling this method
        // Many methods later on use either this.store.currentUser or a user parameter, which should be probably changed
        this.uiStore.currentUser = user;

        if (!user.hasCompletedRegistration) {
          this.uiStore.showRegistration();
        } else {
          user.setCalendarAvailabilities();
        }

        // Usually employer work orders are queried in UiStore when navigating into the view, but it needs the current user
        // In cases where currentUser is not null (login not complete, e.g. when refreshing the page), we need to call the method here
        // if (this.uiStore.currentView?.name === 'employer-work-orders' || this.uiStore.currentView?.name === 'employer-billing') {
        //   this.employerWorkOrderStore.getEmployerWorkOrders();
        // }

        if (cb != null) {
          cb();
        }

        return user;
      })();
    } catch (err) {
      // The error catching logic is incorrect currently, fails here and not in login() as intended
      this.isLoggingIn = false;
      console.log('Error: ', err);
      return null;
    }
  }

  // TODO: implement login
  @action login = (afterLogin) => {
    // TODO: Pick a better animation trigger, this only works because the request
    // creates a pause between the string assignments
    this.shakeLoginButton = false;
    this.isLoggingIn = true;
    this.feedbackText = '';
    // Should be redundant since this is already done on logout but just in case
    this.archivedOffset = 0;

    this.requests.Users.login(this.emailValue, this.passwordValue)
      .then(() => {
        this.isLoggedIn = true;
        this.findMe()
          .then((user) => {
            afterLogin(user);
            this.isLoggingIn = false;
          })
          .catch(() => {
            this.isLoggingIn = false;
          });
      })
      .catch(
        action((e) => {
          /* if (this.errorStore.hasError && this.errorStore.errorStatus === 401) {
            this.loginFailed = true;
            this.shakeLoginButton = true;
            this.isLoggingIn = false;
            this.feedbackText = this.errorStore.handleLoginError();
          } */
          this.loginFailed = true;
          this.shakeLoginButton = true;
          this.isLoggingIn = false;
          this.feedbackText = e.message === 'Unauthorized' ? 'Tarkista käyttäjätunnus ja salasana.' : 'Tarkista internet-yhteys.';
          console.log('Error: ', e);
        }),
      );
  };
}
