/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import { fromPromise } from 'mobx-utils';
// import { when } from 'mobx';
// import { TextField } from '@rmwc/textfield';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
// import { Button } from '@rmwc/button';
// import { Elevation } from '@rmwc/elevation';
// import { IconButton } from '@rmwc/icon-button';
// import { Checkbox } from '@rmwc/checkbox';
// import { Icon } from '@rmwc/icon';
// import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
// import {
//   List,
//   ListGroup,
//   ListItem,
//   ListItemMeta,
//   ListDivider,
//   ListGroupSubheader,
//   ListItemText,
// } from '@rmwc/list';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from '@rmwc/dialog';
// import './WorkTaskDialog.css';
import '../../employer-work-orders/work-tasks/WorkTaskDialog.css';
import FilePreviewDialog from '../../shared/FilePreviewDialog';
import moment from '../../utils/moment';

// const handleEnter = (e) => {
//   if (e.key === 'Enter') {
//     document.activeElement.blur();
//   }
// };

@inject('t') // employerWorkOrderStore
@observer
class BulletinBoardPostViewDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bulletinBoardPost: props.bulletinBoardPost,
      showFilePreviewDialog: false,
    };
  }

  // Is is probably an overcomplicated way of ensuring that the dialog's state (the opened work task) stays up-to-date
  // Figure out a better solution, preferrably from an older edit dialog implementation
  // componentDidUpdate(prevProps) {
  //   const { bulletinBoardPost } = this.props;
  //   const { bulletinBoardPost: prevBulletinBoardPost } = prevProps;
  //   const { id, files } = this.state;

  //   // if (!isEqual(bulletinBoardPost, prevBulletinBoardPost) && (bulletinBoardPost.id !== id || workTask?.files?.length !== files?.length)) {
  //   if (!isEqual(bulletinBoardPost, prevBulletinBoardPost)) {
  //     this.setState({
  //       bulletinBoardPost,
  //       errors: {},
  //     });
  //   }
  // }

  renderUploadedFile(file) {
    const filename = file.filename;
    const fileURL = file.fileURL;

    const createdAt = moment(file.createdAt).local().format('dd DD.MM. HH:mm');
    return (
      <GridInner key={fileURL}>
        <GridCell
          // desktop={8}
          // phone={3}
          span={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            // alignSelf: 'center',
          }}
        >
          <a
            style={{
              color: 'var(--mdc-theme-primary, #6200ee)',
              cursor: 'pointer',
              padding: '10px 0 0 0',
              // fontSize: '14px'
            }}
            onClick={() => {
              this.setState({
                fileUrl: fileURL,
                fileName: filename,
                showFilePreviewDialog: true,
              })
            }}
          >
            {filename}
          </a>

          <span
            style={{ color: 'white', textTransform: 'capitalize', fontSize: '12px', marginLeft: '10px' }}
          >
            {createdAt}
          </span>
        </GridCell>
      </GridInner>
    );
  }

  renderBottomButtons() {
    const { mode } = this.props;
    const {
      inputChanged,
      id,
      identifierOne,
    } = this.state;

    if (inputChanged) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // onClick={() => {
            //   this.handleValidate(identifierOne);
            // }}
            action="accept"
          >
            Tallenna
          </DialogButton>

          <DialogButton
            type="button"
            className={mode === 'employer' ? 'employer-reject-button' : 'cancel-button'}
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Peruuta
          </DialogButton>
        </DialogActions>
      );
    } else {
      return (
        <DialogActions
          style={{
            justifyContent: 'end',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className={mode === 'employer' ? 'employer-reject-button' : 'cancel-button'}
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    }
  }

  sortByCreatedAtAsc(a, b) {
    // Assuming ISO 8601 format, which is sortable like any string
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    return 0;
  }

  closeFilePreviewDialog = () => {
    this.setState({
      showFilePreviewDialog: false,
      fileUrl: null,
      fileName: null,
    })
  }

  render() {
    const { open, handleClose, bulletinBoardPost, mode } = this.props;
    const { showFilePreviewDialog, fileUrl, fileName } = this.state;
    // const inputRef = React.createRef();
    console.log('Open post in view dialog: ', bulletinBoardPost);
    const sortedFiles = bulletinBoardPost.files?.sort(this.sortByCreatedAtAsc);

    return (
      <>
        <Dialog
          className={mode === 'employer' ? "modal-size employer-modal employer-work-task-dialog" : "modal-size employee-work-task-dialog trip-route-dialog"}
          open={open}
          onClose={(evt) => {
            handleClose();
          }}
        >
          <DialogTitle>
            {bulletinBoardPost.title}
          </DialogTitle>
          <DialogContent>
            <Grid>
              <GridCell
                className="employer-work-order"
                span={12}
              >
                {/* <div style={{ fontSize: '16px' }}>
                  {identifierTwo ? `${identifierOne}-${identifierTwo}` : identifierOne}
                </div> */}
                <div style={{ color: 'white', whiteSpace: 'pre-wrap', fontSize: '14px' }}>
                  {bulletinBoardPost.description}
                </div>
              </GridCell>


              <GridCell
                span={12}
              >
                {sortedFiles?.map((file) => {
                  return this.renderUploadedFile(file);
                })}
              </GridCell>
            </Grid>
          </DialogContent>
          {this.renderBottomButtons()}
        </Dialog>

        <FilePreviewDialog
          open={showFilePreviewDialog}
          fileURL={fileUrl}
          fileName={fileName}
          onClose={this.closeFilePreviewDialog}
        />
      </>
    )
  }
}

export default BulletinBoardPostViewDialog;
