import { action, observable } from 'mobx';
import moment from '../utils/moment';
import BulletinBoardCategory from './BulletinBoardCategory';

export default class BulletinBoardPost {
  @observable id;

  @observable title;

  @observable bulletinBoardCategory;

  @observable description;

  @observable files = [];

  @observable visibleToEmployees;

  @observable visibleToEmployers;

  @observable visibleToSubcontractors;

  // constructor({
  //   id,
  //   title,
  //   bulletinBoardCategory,
  //   files,
  //   description,
  // }) {
  //   this.id = id;
  //   this.title = title;
  //   this.bulletinBoardCategory = bulletinBoardCategory;
  //   this.files = files;
  //   this.description = description;
  // }

  constructor(object) {
    this.updateProperties(object);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  // static toJson(o) {
  //   return {
  //     // file: o.file,
  //     files: o.files,
  //     bulletinBoardCategory: o.bulletinBoardCategory?.id,
  //     id: o.id,
  //     title: o.title,
  //     description: o.description,
  //   };
  // }

  static toJson(o) {
    const formData = new FormData();
    formData.append('title', o.title || undefined);
    formData.append('id', o.id || undefined);
    formData.append('bulletin_board_category_id', o.bulletinBoardCategory?.id || undefined);
    // null is saved as a string so we need to set it as undefined instead
    formData.append('description', o.description || undefined);
    formData.append('visible_to_employers', o.visibleToEmployers || false);
    formData.append('visible_to_employees', o.visibleToEmployees || false);
    formData.append('visible_to_subcontractors', o.visibleToSubcontractors || false);
    // formData.append('files', o.files);
    // Append each file individually for multiple files
    if (o.files && o.files.length > 0) {
      o.files.forEach((file) => {
        // Only append files that haven't been uploaded yet
        if (!file.signedId) {
          formData.append('files[]', file);
        }
      });
    }

    return formData;
  }

  @action updatePropertiesFromJson(o) {
    const values = {
      createdAt: moment(o.created_at).local(),
      // filename: o.filename,
      // fileURL: o.file_url,
      files: o.files && o.files.length > 0 ? o.files.map((file) => ({
        filename: file.filename,
        fileURL: file.url,
        signedId: file.signed_id,
        createdAt: file.created_at,
        // uploadedBy
      })) : [],
      description: o.description,
      id: o.id,
      title: o.title,
      bulletinBoardCategory: o.bulletin_board_category && BulletinBoardCategory.fromJsonProperties(o.bulletin_board_category),
      // uploadedBy: User.fromJsonProperties(o.uploaded_by),
      pinned: o.pinned,
      createdBy: o.created_by,
      visibleToEmployees: o.visible_to_employees,
      visibleToEmployers: o.visible_to_employers,
      visibleToSubcontractors: o.visible_to_subcontractors,
    };

    this.updateProperties(values);
  }

  static fromJsonProperties(o) {
    const bulletinBoardPost = new BulletinBoardPost();
    bulletinBoardPost.updatePropertiesFromJson(o);
    return bulletinBoardPost;
  }
}
