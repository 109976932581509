import { observable, computed, action } from 'mobx';

import moment from '../utils/moment';
import Availability from '../models/Availability';
import WorkOrder from '../calendar/WorkOrder';

const createDateSpans = (days, available) => {
  const availabilities = [];
  days.sort((a, b) => a.diff(b, 'days'));

  let currentSpan = null;

  days.forEach((av) => {
    // If we don't have a span, then it's the start and
    // we'll create a new one, assuming to date to be the
    // start in case of one day long spans
    if (!currentSpan) {
      currentSpan = new Availability({
        available,
        from: av,
        to: av,
      });

      availabilities.push(currentSpan);
    }

    const { to } = currentSpan;

    const gapInDays = moment.duration(av.diff(to)).days();

    // If there's a gap larger than two days then it's a start of a new
    // span
    if (gapInDays > 1) {
      currentSpan = new Availability({
        available,
        from: av,
        to: av,
      });

      availabilities.push(currentSpan);
    }

    // Otherwise we're in the same gap and can just continue
    // and increment the to part
    currentSpan.to = av;
  });

  return availabilities;
};

class UiStore {
  @observable currentUser = null;

  @observable currentView = null;

  @observable menuOpen = false;

  @observable employerMode = false;

  @observable clickedTab = 0;

  // TODO: Might not be the best place for this
  @observable refreshing = false;

  @observable mobileMode = true;

  @observable currentEmployerAcceptanceMode = null;

  @observable employerMiniMenuLocked = false;

  @observable resetPasswordToken = null;

  constructor(requests, employerWorkOrderStore) {
    this.requests = requests;
    this.employerWorkOrderStore = employerWorkOrderStore;
  }

  toggleMenuLock() {
    this.employerMiniMenuLocked = !this.employerMiniMenuLocked;
  }

  canView(view) {
    return this.currentUser !== null || !view.secured;
  }

  hasNoCurrentUser() {
    return this.currentUser == null;
  }

  setMenuOpen(status) {
    this.menuOpen = status;
  }

  setMobileMode(mode) {
    this.mobileMode = mode;
  }

  setCurrentEmployerAcceptanceMode = (mode) => {
    this.currentEmployerAcceptanceMode = mode;
  }

  setEmployerMode(status) {
    this.menuOpen = false;
    this.employerMode = status;
    if (status) {
      // this.showEmployerTimelog();
      this.showEmployerWorkOrders();
    } else if (this.currentUser) {
      this.showCalendar();
    }
  }

  // TODO: Updating attachements feels kinda hacky
  // NOTE: This is for updating currentUser (the user themselves)
  @action updateUser() {
    return this.requests.Users.update(this.currentUser).then(
      action((user) => this.currentUser.updatePropertiesFromJson(user)),
    );
  }

  // NOTE: This is for purging user's own helmet image, as opposed to another user's
  @action purgeHelmetImage() {
    this.requests.Users.purgeHelmetImage(this.currentUser);
  }

  // NOTE: This is for purging user's own tax income card image, as opposed to another user's
  @action purgeTaxIncomeCard() {
    this.requests.Users.purgeTaxIncomeCard(this.currentUser);
  }

  @action purgeCard(card) {
    return this.requests.Users.purgeCard(this.currentUser, card);
  }

  @action markDaysAsAvailable(availableDays, busyDays) {
    const availabilities = [
      ...createDateSpans(availableDays, true),
      ...createDateSpans(busyDays, false),
    ];

    console.log('saving...', availabilities);
    return this.requests.Availability.del(this.currentUser).then(
      async () => {
        const newAvailabilities = await Promise.all(
          availabilities.map((av) => this.requests.Availability.add(this.currentUser, av)),
        );

        this.currentUser.updateProperties({ availability: newAvailabilities });
      },
    );
  }

  @action acceptWorkOrderInvitation(invitation) {
    // return this.requests.WorkOrderInvitations.accept(invitation).then(() => this.currentUser.acceptInvitation(invitation));
    return this.requests.WorkOrderInvitations.accept(invitation).then((response) => {
      this.currentUser.acceptInvitation(invitation);
      if (response.availabilities && response.availabilities.length > 0) {
        this.currentUser.updateProperties({ availability: response.availabilities });
      }

      // Remove the old, already loaded invitation
      const updatedInvitations = this.currentUser.invitations.filter((oldInvitation) => oldInvitation.id !== invitation.id);
      this.currentUser.invitations.replace(updatedInvitations);

      if (response.work_order && response.work_order.account_id === this.currentUser.currentAccount.account_id) {
        const woObj = WorkOrder.fromJsonProperties(response.work_order);
        this.currentUser.workOrders.replace([woObj, ...this.currentUser.workOrders]);
        this.currentUser.setCalendarAvailabilities();
      }
    });
  }

  @action rejectWorkOrderInvitation(invitation) {
    return this.requests.WorkOrderInvitations.reject(invitation).then(() => {
      this.currentUser.rejectInvitation(invitation);
    });
  }

  @action updateWorkOrderInvitation(invitation, resolve, reject) {
    return this.requests.WorkOrderInvitations.update(invitation).then((response) => {
      resolve(response);
    }).catch((err) => reject(err));
  }

  @action showLogin() {
    this.currentView = {
      name: 'login',
      needsHeader: false,
      headerMargin: 'small',
      headerMarginMobile: 'smallest',
    };
  }

  @action showChangePassword(token) {
    this.currentView = {
      secured: false,
      name: 'reset-password',
      needsHeader: false,
      headerMargin: 'small',
      headerMarginMobile: 'small',
      resetPasswordToken: token,
    };
  }

  @action showCalendar() {
    this.currentView = {
      name: 'calendar',
      secured: true,
      needsHeader: true,
      headerMargin: 'default',
      headerMarginMobile: 'default',
    };
  }

  @action showWorkOrder(id) {
    this.currentView = {
      name: 'work-order',
      secured: true,
      needsHeader: false,
      headerMargin: 'small',
      headerMarginMobile: 'small',
      id,
    };
  }

  @action showChatRoom(id) {
    this.currentView = {
      name: 'chat-room',
      secured: true,
      needsHeader: true,
      headerMargin: 'default',
      headerMarginMobile: 'default',
      id,
    };
  }

  @action showBulletinBoardCategory(id) {
    this.currentView = {
      name: 'bulletin-board-category',
      secured: true,
      needsHeader: true,
      headerMargin: 'default',
      headerMarginMobile: 'default',
      id,
    };
  }

  @action showProfile() {
    this.currentView = {
      name: 'profile',
      secured: true,
      needsHeader: true,
      headerMargin: 'default',
      headerMarginMobile: 'default',
    };
  }

  @action showTimeEntries() {
    this.currentView = {
      name: 'timelog',
      secured: true,
      needsHeader: true,
      headerMargin: 'default',
      headerMarginMobile: 'default',
    };
  }

  @action showMessages() {
    this.currentView = {
      name: 'messages',
      secured: true,
      needsHeader: true,
      headerMargin: 'default',
      headerMarginMobile: 'default',
      messagesTab: 0,
    };
  }

  @action showBulletinBoard() {
    this.currentView = {
      name: 'messages',
      secured: true,
      needsHeader: true,
      headerMargin: 'default',
      headerMarginMobile: 'default',
      messagesTab: 1,
    };
  }

  // Not currently in use, App.js renderApp() doesn't connect this path to any component render
  @action showSettings() {
    this.currentView = {
      name: 'settings',
      secured: true,
      needsHeader: true,
      headerMargin: 'default',
      headerMarginMobile: 'default',
    };
  }

  @action showRegistration(token) {
    this.currentView = {
      name: 'registration',
      needsHeader: false,
      headerMargin: 'small',
      headerMarginMobile: 'small',
      secured: true,
      token: (token != null && (token !== 'undefined' || token !== 'null') ? token : ''),
    };
  }

  @action showNotFound() {
    console.log('todo: show not found', this.currentView);
  }

  // Not currently in use? renderApp() tries to open src/invitations.index.js but do the invitations actually use it?
  @action showInvitation(token) {
    console.log('in invitation');
    this.currentView = {
      name: 'invitation',
      token,
      secured: false,
      needsHeader: false,
      headerMargin: 'small',
    };
  }

  @action showEmployerWorkOrders(currentFilters, snackbarMessage) {
    // We want to request employer work orders when the user navigates into the view
    // EXCEPT if the previous view was a single work order OR employer-billing so that the list isn't re-queried unnecessarily
    // currentUser is empty if we're e.g. refreshing the page when in employer-work-orders. getEmployerWorkOrders() is instead run in LoginStore
    if (this.currentUser && (!this.currentView || (this.currentView && this.currentView?.name !== 'employer-work-order' && this.currentView?.name !== 'employer-billing' && this.currentView?.name !== 'employer-timelogs' && this.currentView?.name !== 'employer-work-order-trips')) && this.employerWorkOrderStore.workOrderCache) {
      // this.employerWorkOrderStore.getEmployerWorkOrders(true);
      // Reset the work order cache's work orders so that they can be updated later, but only reset the work orders without touching sorting, paging, etc.
      this.employerWorkOrderStore.workOrderCache.workOrders = null;
    }

    this.employerMode = true;
    this.currentView = {
      name: 'employer-work-orders',
      secured: true,
      needsHeader: true,
      headerMargin: 'none',
      headerMarginMobile: 'medium',
      employerView: true,
      currentFilters,
      snackbarMessage,
    };
  }

  @action showEmployees() {
    this.employerMode = true;
    this.menuOpen = false;
    this.currentView = {
      name: 'employees',
      secured: true,
      needsHeader: true,
      headerMargin: 'none',
      headerMarginMobile: 'smallest',
      employerView: true,
    };
  }

  @action showProducts() {
    this.employerMode = true;
    this.menuOpen = false;
    this.currentView = {
      name: 'products',
      secured: true,
      needsHeader: true,
      headerMargin: 'none',
      headerMarginMobile: 'smallest',
      employerView: true,
    };
  }

  @action showCertificates() {
    this.employerMode = true;
    this.menuOpen = false;
    this.currentView = {
      name: 'certificates',
      secured: true,
      needsHeader: true,
      headerMargin: 'none',
      headerMarginMobile: 'smallest',
      employerView: true,
    };
  }

  @action showSalaryPeriodProcessing() {
    this.employerMode = true;
    this.menuOpen = false;
    this.currentView = {
      name: 'salary-period-processing',
      secured: true,
      needsHeader: true,
      headerMargin: 'none',
      headerMarginMobile: 'smallest',
      employerView: true,
    };
  }

  @action showLocationsPurchasers() {
    this.employerMode = true;
    this.menuOpen = false;
    this.currentView = {
      name: 'locations-purchasers',
      secured: true,
      needsHeader: true,
      headerMargin: 'none',
      headerMarginMobile: 'smallest',
      employerView: true,
    };
  }

  @action showResourceAvailability() {
    this.employerMode = true;
    this.menuOpen = false;
    this.currentView = {
      name: 'resource-availability',
      secured: true,
      needsHeader: true,
      headerMargin: 'none',
      headerMarginMobile: 'smallest',
      employerView: true,
    };
  }

  @action showBulletinBoardPosts() {
    this.employerMode = true;
    this.menuOpen = false;
    this.currentView = {
      name: 'bulletin-board-posts',
      secured: true,
      needsHeader: true,
      headerMargin: 'none',
      headerMarginMobile: 'smallest',
      employerView: true,
    };
  }

  @action emptyActiveTab() {
    this.clickedTab = 0;
  }

  @action setActiveTabAndShowWorkOrder(mode, id, tabIndex) {
    this.clickedTab = tabIndex;
    this.showEmployerWorkOrder(mode, id);
  }

  @action setActiveTabAndShowEmployeeWorkOrder(id, tabIndex) {
    this.clickedTab = tabIndex;
    this.showWorkOrder(id);
  }

  @action showEmployerWorkOrder(mode, id) {
    this.employerMode = true;
    this.currentView = {
      name: 'employer-work-order',
      secured: true,
      needsHeader: false,
      headerMargin: 'none',
      headerMarginMobile: 'smallest',
      employerView: true,
      mode,
      id,
    };
  }

  @action showEmployerTimelogs() {
    this.employerMode = true;
    this.currentView = {
      name: 'employer-timelogs',
      secured: true,
      needsHeader: true,
      headerMargin: 'none',
      headerMarginMobile: 'medium',
      employerView: true,
    };
  }

  @action showTimelogReport() {
    this.employerMode = true;
    this.currentView = {
      name: 'timelog-report',
      secured: true,
      needsHeader: true,
      headerMargin: 'none',
      headerMarginMobile: 'medium',
      employerView: true,
    };
  }

  @action showEmployerWorkOrderTrips() {
    this.employerMode = true;
    this.currentView = {
      name: 'employer-work-order-trips',
      secured: true,
      needsHeader: true,
      headerMargin: 'none',
      headerMarginMobile: 'medium',
      employerView: true,
    };
  }

  @action showEmployerAbsences() {
    this.employerMode = true;
    this.currentView = {
      name: 'employer-absences',
      secured: true,
      needsHeader: true,
      headerMargin: 'none',
      headerMarginMobile: 'medium',
      employerView: true,
    };
  }

  @action showEmployerBilling = (selectedFilter) => {
    // We want to request employer work orders when the user navigates into the view
    // EXCEPT if the previous view was the work orders index so that the list doesn't refresh unnecessarily
    // currentUser is empty if we're e.g. refreshing the page when in employer-billing. getEmployerWorkOrders() is instead run in LoginStore
    if (this.currentUser && (!this.currentView || (this.currentView && this.currentView?.name !== 'employer-work-orders' && this.currentView?.name !== 'employer-billing-attachments')) && this.employerWorkOrderStore.billingWorkOrderCache) {
      // this.employerWorkOrderStore.getEmployerWorkOrders(true);
      // Reset the billing work order cache's work orders so that they can be updated later, but only reset the work orders without touching sorting, paging, etc.
      this.employerWorkOrderStore.billingWorkOrderCache.workOrders = null;
    }

    this.employerMode = true;
    this.menuOpen = false;
    this.currentView = {
      name: 'employer-billing',
      secured: true,
      needsHeader: true,
      headerMargin: 'none',
      headerMarginMobile: 'smallest',
      employerView: true,
      selectedFilter,
    };
  }

  @action showBillingAttachments(id, selectedFilter) {
    this.employerMode = true;
    this.currentView = {
      name: 'employer-billing-attachments',
      secured: true,
      needsHeader: false,
      headerMargin: 'none',
      headerMarginMobile: 'smallest',
      employerView: true,
      id,
      selectedFilter,
    };
  }

  @action showBillingReports(id) {
    // Should be used in other views too but they have architectural problems
    // E.g. the resource that the ID refers to is only loaded in a previous view
    const splitUrl = window.location.href.split('/');
    const urlId = parseInt(splitUrl[splitUrl.length - 1], 0);

    this.employerMode = true;
    this.currentView = {
      name: 'employer-billing-reports',
      secured: true,
      needsHeader: false,
      headerMargin: 'none',
      headerMarginMobile: 'smallest',
      employerView: true,
      id: id || urlId,
    };
  }

  @action showBillingReportsHourly(id) {
    // Should be used in other views too but they have architectural problems
    // E.g. the resource that the ID refers to is only loaded in a previous view
    const splitUrl = window.location.href.split('/');
    const urlId = parseInt(splitUrl[splitUrl.length - 1], 0);

    this.employerMode = true;
    this.currentView = {
      name: 'employer-billing-reports-hourly',
      secured: true,
      needsHeader: false,
      headerMargin: 'none',
      headerMarginMobile: 'smallest',
      employerView: true,
      id: id || urlId,
    };
  }

  @action showProductReport(id) {
    // Should be used in other views too but they have architectural problems
    // E.g. the resource that the ID refers to is only loaded in a previous view
    const splitUrl = window.location.href.split('/');
    const urlId = parseInt(splitUrl[splitUrl.length - 1], 0);

    this.employerMode = true;
    this.currentView = {
      name: 'employer-product-report',
      secured: true,
      needsHeader: false,
      headerMargin: 'none',
      headerMarginMobile: 'smallest',
      employerView: true,
      id: id || urlId,
    };
  }

  @action showSafety() {
    this.employerMode = false;
    this.currentView = {
      name: 'safety',
      secured: true,
      needsHeader: true,
      headerMargin: 'medium',
      headerMarginMobile: 'medium',
      // employerView: true,
    };
  }

  @action showEmployerCloseCallReports() {
    this.employerMode = false;
    this.currentView = {
      name: 'employer-close-call-reports',
      secured: true,
      needsHeader: true,
      headerMargin: 'medium',
      headerMarginMobile: 'medium',
      employerView: true,
    };
  }

  @action showAccidentReports() {
    this.employerMode = false;
    this.currentView = {
      name: 'accident-reports',
      secured: true,
      needsHeader: true,
      employerView: false, // this.currentUser?.role === 'employer',
      headerMargin: 'small',
      headerMarginMobile: 'small',
    };
  }

  @computed get currentPath() {
    // If no view is found then just navigate to calendar
    if (!this.currentView) {
      return '/app/calendar/';
    }

    switch (this.currentView.name) {
      case 'login':
        return '/app/login/';
      case 'registration':
        return `/app/registration/${this.currentView.token}`;
      case 'invitation':
        return `/app/invitations/accept/${this.currentView.token}`;
      case 'calendar':
        if (window.location.search) {
          // This is needed to show the password reset success message on the login screen
          return `/app/calendar${window.location.search}`;
        }
        return '/app/calendar/';
      case 'settings':
        return '/app/settings/';
      case 'profile':
        return '/app/profile/';
      case 'messages':
        return '/app/messages/';
      case 'bulletin-board-category':
        return `/app/bulletin-board-category/${this.currentView.id}`;
      case 'timelog':
        return '/app/timelog/';
      case 'work-order':
        return `/app/work-orders/${this.currentView.id}`;
      case 'chat-room':
        return `/app/chat/${this.currentView.id}`;
      // Employer mode-only views
      case 'employer-work-orders':
        return '/app/employer-work-orders/';
      case 'employer-timelogs':
        return '/app/employer-timelogs/';
      case 'timelog-report':
        return '/app/timelog-report';
      case 'employer-work-order-trips':
        return '/app/employer-work-order-trips';
      case 'employer-absences':
        return '/app/employer-absences';
      case 'employer-work-order':
        return this.currentView.id ? `/app/employer-work-order/${this.currentView.id}` : '/app/employer-work-order/';
      case 'employer-billing':
        return '/app/employer-billing';
      case 'resource-availability':
        return '/app/resource-availability';
      case 'employer-billing-attachments':
        return '/app/employer-billing-attachments';
      case 'employer-billing-reports':
        return `/app/employer-billing-reports/${this.currentView.id}`;
      case 'employer-billing-reports-hourly':
        return `/app/employer-billing-reports-hourly/${this.currentView.id}`;
      case 'employer-product-report':
        return `/app/employer-product-report/${this.currentView.id}`;
      case 'safety':
        return '/app/safety';
      case 'employer-close-call-reports':
        return '/app/employer-close-call-reports';
      case 'accident-reports':
        return '/app/accident-reports';
      case 'locations-purchasers':
        return '/app/locations-purchasers';
      case 'employees':
        return '/app/employees';
      case 'products':
        return '/app/products';
      case 'salary-period-processing':
        return '/app/salary-period-processing';
      case 'certificates':
        return '/app/certificates';
      case 'reset-password':
        return `/app/reset-password/${this.currentView.resetPasswordToken}`;
      case 'bulletin-board-posts':
        return '/app/bulletin-board-posts';
      default:
        return '/app/calendar/';
    }
  }

  @computed get isAuthenticated() {
    return this.currentUser !== null;
  }
}

export default UiStore;
