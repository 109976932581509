import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Card, CardPrimaryAction } from '@rmwc/card';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';

@inject('uiStore', 'chatStore', 't')
@observer
class BulletinBoardPostsHeader extends Component {
  render() {
    const {
      bulletinBoardCategory,
      closeBulletinBoardCategory,
    } = this.props;

    return (
      <Card
        key={bulletinBoardCategory?.id}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <CardPrimaryAction
            style={{
              width: '100%',
            }}
            onClick={() => closeBulletinBoardCategory()}
          >
            <div
              style={{
                padding: '0 1rem',
                width: '100%',
              }}
            >
              <Typography {...{
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  lineHeight: '1.25rem',
                  marginBottom: '0.5em',
                  marginTop: '0.5em',
                },
                tag: 'h6',
                theme: 'textSecondaryOnDark',
                use: 'subtitle1',
              }}
              >
                <Icon
                  icon="arrow_back"
                  style={{
                    paddingRight: '0.5em',
                    alignSelf: 'normal',
                  }}
                />
                {bulletinBoardCategory?.name}
              </Typography>
            </div>
          </CardPrimaryAction>
        </div>
      </Card>
    );
  }
}

export default BulletinBoardPostsHeader;
