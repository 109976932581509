import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import { TextField } from '@rmwc/textfield';
// import { Grid, GridCell } from '@rmwc/grid';
// import {
//   Dialog, DialogTitle, DialogActions, DialogButton, DialogContent,
// } from '@rmwc/dialog';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  ThemeProvider,
  IconButton,
  Icon,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { Description, DeleteOutline } from '@material-ui/icons';
// import DeleteOutlineIcon from '@material-ui/icons';
import BulletinBoardPost from '../../models/BulletinBoardPost';
import theme from '../../muiTheme';
import './BulletinBoardPostDialog.css';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

const titleValidate = (title) => {
  if (!title) {
    return 'Otsikko on pakollinen';
  }
  return null;
};


const categoryValidate = (category) => {
  if (!category) {
    return 'Kategoria on pakollinen';
  }
  return null;
};

const sortByCreatedAtAsc = (a, b) => {
  // Assuming ISO 8601 format, which is sortable like any string
  if (a.createdAt < b.createdAt) return -1;
  if (a.createdAt > b.createdAt) return 1;
  return 0;
};

const validate = {
  // attachments: (description, files) => descriptionOrFileValidation(description, files),
  title: (name) => titleValidate(name),
  bulletinBoardCategory: (category) => categoryValidate(category),
};

@inject('bulletinBoardStore', 'loginStore', 't')
@observer
class BulletinBoardPostDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ...props.purchaser,
      bulletinBoardPost: props.bulletinBoardPost,
      createdBulletinBoardCategory: props.createdBulletinBoardCategory,
      // id: props.id,
      // name: props.dialogValue || props.purchaser?.name || '',
      // purchaser: props.purchaser,
      errors: {},
      inputChanged: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { bulletinBoardPost, createdBulletinBoardCategory, open } = this.props;

    // Check if the dialog has just been opened
    if ((!prevProps.open && open) || prevProps.bulletinBoardPost?.id !== bulletinBoardPost?.id) {
      const updatedBulletinBoardPost = new BulletinBoardPost(bulletinBoardPost);
      if (prevProps.createdBulletinBoardCategory?.id !== createdBulletinBoardCategory?.id) {
        updatedBulletinBoardPost.changeAttribute('bulletinBoardCategory', createdBulletinBoardCategory);
      }

      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({
        // id: purchaser?.id || '',
        // name: dialogValue || purchaser?.name || '',
        // street: purchaser?.street || '',
        // zipCode: purchaser?.zipCode || '',
        // city: purchaser?.city || '',
        bulletinBoardPost: updatedBulletinBoardPost,
        errors: {},
        inputChanged: false,
      });
      // Original first case changed to here
    }
    // else if (prevProps.purchaser?.id !== purchaser?.id) {
    //   /* eslint-disable-next-line react/no-did-update-set-state */
    //   this.setState({
    //     // id: purchaser?.id || '',
    //     // name: purchaser?.name || dialogValue || '',
    //     // street: purchaser?.street || '',
    //     // zipCode: purchaser?.zipCode || '',
    //     // city: purchaser?.city || '',
    //     bulletinBoardPost: bulletinBoardPost,
    //     errors: {},
    //   });
    // }
  }

  setFile(file) {
    this.setState((prevState) => ({
      bulletinBoardPost: { ...prevState.bulletinBoardPost, files: [...prevState.bulletinBoardPost.files, file] },
    }));
  }

  addTextField(attr, label, required, textarea, styles = {}) {
    const { errors, bulletinBoardPost } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <TextField {...{
          // className: 'employer-dialog-input',
          className: 'input-hacked-paddings',
          label,
          required,
          name: attr,
          rows: 5,
          invalid: errors[attr],
          helperText: errors[attr] && errors[attr],
          multiline: textarea,
          onChange: (event) => {
            const { value } = event.target;

            const updatedBulletinBoardPost = new BulletinBoardPost(bulletinBoardPost);
            updatedBulletinBoardPost.changeAttribute(attr, value);
            this.setState({
              // [attr]: value,
              bulletinBoardPost: updatedBulletinBoardPost,
              inputChanged: true,
            });

            delete errors[attr];
          },
          // rootProps: { ripple: false },
          onKeyPress: !textarea ? handleEnter : () => { },
          /* eslint-disable-next-line react/destructuring-assignment */
          value: bulletinBoardPost[attr] != null ? bulletinBoardPost[attr] : '',
          variant: 'filled',
          style: { ...styles },
          // style: {
          //   backgroundColor: '#FFFFFF',
          //   width: '100%',
          //   margin: '0.3em 0',
          //   whiteSpace: 'pre',
          // },
        }}
        />
      </ThemeProvider>
    );
  }

  inputDropDown(attr, label, required, styles) {
    const { bulletinBoardPost, errors } = this.state;
    const { bulletinBoardCategories } = this.props;

    // Sort the purchasers and locations alphabetically
    const sortedCategories = bulletinBoardCategories?.slice().sort((a, b) => a.name.localeCompare(b.name, 'fi'));

    return (
      <ThemeProvider theme={theme}>
        <Autocomplete
          freeSolo
          options={sortedCategories || []}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          value={bulletinBoardPost?.[attr] || null}
          // eslint-disable-next-line react/destructuring-assignment
          inputValue={this.state[`inputValue_${attr}`] || ''}
          onInputChange={(event, newInputValue) => {
            this.setState({ [`inputValue_${attr}`]: newInputValue });
          }}
          onChange={(event, newValue) => {
            delete errors[attr];

            if (typeof newValue === 'string') {
              this.openBulletinBoardCategoryDialog(newValue);
              this.setState({ [`inputValue_${attr}`]: '' }); // Clear the input after opening dialog
            } else if (newValue && newValue.inputValue) {
              this.openBulletinBoardCategoryDialog(newValue.inputValue);
              this.setState({ [`inputValue_${attr}`]: '' }); // Clear the input after opening dialog
            } else if (newValue) {
              this.setState({
                bulletinBoardPost: { ...bulletinBoardPost, [attr]: newValue },
                [`inputValue_${attr}`]: newValue.name,
                inputChanged: true,
              });
            }
          }}
          filterOptions={(fOptions, params) => {
            const filtered = fOptions.filter((fOption) => (
              fOption.name.toLowerCase().includes(params.inputValue.toLowerCase())
            ));

            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Lisää "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          renderInput={(params) => (
            <TextField
              variant="filled"
              {...params}
              label={label}
              required={required}
              error={!!errors[attr]}
              helperText={errors[attr] && errors[attr]}
              style={styles}
            />
          )}
        />
      </ThemeProvider>
    );
  }

  openBulletinBoardCategoryDialog() {
    const { openBulletinBoardCategoryDialog } = this.props;
    // this.setState({
    //   bulletinBoardCategoryDialogOpen: true,
    // });
    openBulletinBoardCategoryDialog({});
  }

  renderActionButtons() {
    const {
      inputChanged,
      bulletinBoardPost,
    } = this.state;
    const { id, handleClose, saveBulletinBoardPost } = this.props;

    if (!id && (!bulletinBoardPost?.title || !bulletinBoardPost?.bulletinBoardCategory)) {
      return (
        <DialogActions
          style={{
            justifyContent: 'end',
            padding: '15px',
          }}
        >
          <Button
            // type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // action="close"
            onClick={() => handleClose()}
          >
            Sulje
          </Button>
        </DialogActions>
      );
    }
    if (!id && bulletinBoardPost?.title && bulletinBoardPost?.bulletinBoardCategory) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            // type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // onClick={() => {
            //   this.handleValidate(name);
            // }}
            // action={name ? 'accept' : null}
            onClick={() => saveBulletinBoardPost(bulletinBoardPost)}
          >
            Tallenna
          </Button>

          <Button
            // type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // action="close"
            onClick={() => handleClose()}
          >
            Sulje
          </Button>
        </DialogActions>
      );
    }
    if (id && inputChanged) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            // type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
          // onClick={() => {
          //   this.handleValidate(name);
          // }}
          // action={name ? 'accept' : null}
          >
            Tallenna
          </Button>

          <Button
            // type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // action="close"
            onClick={() => handleClose()}
          >
            Peruuta
          </Button>
        </DialogActions>
      );
    }
    if (bulletinBoardPost?.id && !inputChanged) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            // type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // action="close"
            onClick={() => handleClose()}
          >
            Sulje
          </Button>
        </DialogActions>
      );
    }
    return null;
  }

  renderUploadedFile(file) {
    const { confirmDeleteFile, bulletinBoardPost: { id: bulletinBoardPostId }, openFilePreviewDialog } = this.props;
    // Name and size are only relevant when uploading new files
    const {
      filename,
      fileURL,
      signedId,
      name,
      size,
    } = file;

    console.log('RENDERING FILE: ', file);

    // We want to hide checked files during PDF generation
    // generating PDF = true
    // AND
    // generatingPdfFiles.includes
    return (
      <div key={fileURL || `${name}-${size}`} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
          }}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            role="button"
            // href={fileURL}
            // download={filename}
            style={{
              color: '#FFC900',
              fontSize: '16px',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={() => openFilePreviewDialog(file)}
          >
            {filename || name}
          </a>
        </div>
        <div>
          {signedId && (
            <IconButton
              // type="button"
              style={{
                color: '#645F5F',
              }}
              onClick={() => {
                confirmDeleteFile('file', bulletinBoardPostId, filename, signedId);
              }}
            >
              <Icon>delete_outlined</Icon>
            </IconButton>
          )}
          {!signedId && <div style={{ height: '48px' }} />}
        </div>
      </div>
    );
  }

  renderCheckbox(attr, label) {
    const { bulletinBoardPost } = this.state;

    return (
      <FormControlLabel
        label={label}
        control={(
          <Checkbox {...{
            className: 'employer-checkbox',
            checked: bulletinBoardPost[attr],
            onChange: (event) => {
              const { target: { checked } } = event;
              console.log('EVENT: ', checked);
              // const { bulletinBoardPost } = this.state;
              this.setState((prevState) => ({
                bulletinBoardPost: { ...prevState.bulletinBoardPost, [attr]: checked },
              }));
            },
          }}
          />
        )}
      />
    );
  }

  render() {
    const {
      t,
      open,
      handleClose,
      saveBulletinBoardPost,
      // purchaser,
      confirmDelete,
    } = this.props;
    const { bulletinBoardPost, bulletinBoardPost: { files } } = this.state;

    const styles = {
      backgroundColor: '#FFFFFF',
      width: '100%',
      margin: '0.3em 0',
    };

    console.log('Rendering post (check files): ', bulletinBoardPost);
    const sortedFiles = files?.sort(sortByCreatedAtAsc) || [];

    return (
      <Dialog
        // className="modal-size employer-modal"
        fullWidth
        // maxWidth="800px"
        open={open}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            let errors = {};

            Object.keys(validate).forEach((key) => {
              /* eslint-disable-next-line react/destructuring-assignment */
              const value = this.state[key];

              // Calling the attr's validation method
              const error = validate[key](value);

              if (error) {
                errors = { ...errors, [key]: error };
              }
            });

            if (Object.keys(errors).length > 0) {
              this.setState({ errors });
            } else {
              // Transform the fields to start with uppercase
              // const transformedData = {
              //   ...this.state,
              //   title: title.charAt(0).toUpperCase() + title.slice(1),
              //   id: bulletinBoardPost.id,
              // };
              saveBulletinBoardPost(bulletinBoardPost);
              handleClose();
            }
          } else {
            handleClose();
          }
        }}
        PaperProps={{
          style: {
            height: '100%',
            maxHeight: '100%',
            margin: 0,
            padding: 0,
          },
        }}
      >
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ margin: 'auto 0' }}>
              {bulletinBoardPost.id ? 'Muokkaa ilmoitusta' : 'Uusi ilmoitus'}
            </div>
            {bulletinBoardPost.id && (
              <IconButton
                style={{
                  color: '#645F5F',
                }}
                aria-label="delete"
                onClick={() => {
                  // this.confirmDeleteWorkPeriod();
                  confirmDelete('bulletinBoardPost', bulletinBoardPost.title, bulletinBoardPost.id);
                }}
              >
                <DeleteOutline />
              </IconButton>
            )}
          </div>
        </DialogTitle>
        <DialogContent>
          {this.addTextField('title', 'Otsikko', true, false, styles)}
          {this.addTextField('description', 'Sisältö', false, true, styles)}

          {this.inputDropDown('bulletinBoardCategory', t('bulletin_board_category.model_name'), true, styles)}

          {/* {bulletinBoardPost.files && bulletinBoardPost.files.length > 0 && bulletinBoardPost.files.map((file) => (
            <div style={{ color: 'black', fontSize: '12px' }}>{file.filename}</div>
          ))} */}

          {sortedFiles && sortedFiles.length > 0 && (
            <div style={{ padding: '10px' }}>
              {sortedFiles.map((file) => this.renderUploadedFile(file))}
            </div>
          )}

          <div style={{ textAlign: 'right', marginTop: '30px' }}>
            {/* <label
              htmlFor="file-upload"
              className="validity-file-upload accept-button mdc-button"
            >
              <span style={{ marginLeft: '10px' }}>Lisää tiedosto</span>
            </label> */}

            {/* <Button {...{
              disabled,
              type: 'button',
              label: 'Lisää tiedosto',
              icon: 'description',
              outlined: true,
              theme: 'secondary',
              // className: 'ml-1',
              onClick: () => inputRef.current.click(),
              style: {
                // color: buttonColors.green.color,
                borderRadius: '0px',
                fontSize: '.75rem',
                textTransform: 'none',
              },
            }}
            /> */}
            <Button
              htmlFor="file-upload"
              // type="button"
              // className="employer-reject-button"
              startIcon={<Description />}
              outlined
              style={{
                borderRadius: '0px',
                minWidth: '100px',
                textTransform: 'inherit',
                // fontWeight: 'normal',
                border: '1px solid #FFC900',
                color: '#2F3440',
                fontSize: '12px',
                padding: '6px 15px',
                letterSpacing: '0.09em',
              }}
            >
              Lisää tiedosto
            </Button>

            <div style={{ fontSize: '18px', textAlign: 'start' }}>
              Näkyvyys
            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {this.renderCheckbox('visibleToEmployers', 'Työnjohtajat')}
                {this.renderCheckbox('visibleToEmployees', 'Työntekijät')}
                {this.renderCheckbox('visibleToSubcontractors', 'Alihankkijat')}
              </div>
            </div>

            <input
              id="file-upload"
              type="file"
              onChange={(e) => this.setFile(e.target.files[0])}// this.setFile(e.target.files[e.target.files.length])}
              accept="image/png, image/jpeg, image/jpg, application/pdf"
              style={{ display: 'none' }}
            />
          </div>
        </DialogContent>
        {this.renderActionButtons()}
      </Dialog>
    );
  }
}

export default BulletinBoardPostDialog;
