/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
// import { Grid, GridInner, GridCell } from '@rmwc/grid';
import AttachFileIcon from '@material-ui/icons/AttachFile';
// import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
// import MessageIcon from '@material-ui/icons/Message';
// import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';
// import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
// import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import { Dialog, DialogContent, DialogActions, Button, Link } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { Elevation } from '@rmwc/elevation';
import { Snackbar } from '@rmwc/snackbar';
import LoadingSpinner from '../../shared/LoadingSpinner';
// import moment from '../utils/moment';
import BulletinBoardPostDialog from './BulletinBoardPostDialog';
import BulletinBoardCategoryDialog from './BulletinBoardCategoryDialog';

// import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';

// import EmployerWorkOrderCard from './employerWorkOrderCard';
// import FilterDialog from '../shared/FilterDialog';
// import EmployerChatDialog from './EmployerChatDialog';
import './index.css';
import BulletinBoardPost from '../../models/BulletinBoardPost';
import BulletinBoardCategory from '../../models/BulletinBoardCategory';

// Consider using mobx observer + fromPromise with the render() instead of this
// See: https://github.com/mobxjs/mobx-utils#frompromise
// const renderWorkOrderCards = (items, noDataText, activeFilters, openEmployerChatDialog) => {
//   if (!items || items.length === 0) {
//     // DB query not yet complete
//     return (
//       <div style={{ textAlign: 'center', padding: '20px' }}>
//         <LoadingSpinner color="black" />
//       </div>
//     )
//   }
//   // else if (items.length === 0) {
//   //   return <p>{noDataText}</p>;
//   // }

//   return (
//     items.map((woItem) => (
//       <EmployerWorkOrderCard
//         from={woItem.from}
//         key={`woitem-${woItem.id}`}
//         to={woItem.to}
//         workOrder={woItem}
//         // selectedFilter={selectedFilter}
//         currentFilters={activeFilters}
//         openEmployerChatDialog={openEmployerChatDialog}
//       // addToWorkOrderCardList={addToWorkOrderCardList}
//       />
//     ))
//   );
// };

@inject('uiStore', 'loginStore', 't', 'bulletinBoardStore')
@observer
class EmployerBulletinBoardPosts extends Component {
  constructor(props) {
    super(props);
    // const { employerWorkOrderStore, uiStore: { currentUser } } = this.props;

    // const showCalendar = new URLSearchParams(window.location.search).get('calendar');

    this.defaultWorkOrdersPerPage = 25;
    this.defaultOffset = 0;

    this.state = {
      showAddNewWorkOrder: false,
      filterDialogOpen: false,
      // currentWorkOrdersWithMeta: [] || workOrdersWithMeta,

      // Normally array, initializing as null here so that we can tell if the db query is complete or not
      currentWorkOrdersWithMeta: null,
      filterCount: 0,
      offset: 0,
      employerChatDialogOpen: false,
      chatId: null,

      bulletinBoardCategories: [],
      bulletinBoardPosts: [],
      bulletinBoardPostsCount: 0,
      workOrdersPerPage: this.defaultWorkOrdersPerPage,
      // viewMode: props.uiStore.mobileMode ? 'mobile' : 'desktop',
      // showCalendar,
      // calendarMode: false,
      openedBulletinBoardPost: {},
      openedBulletinBoardCategory: {},
      bulletinBoardPostDialogOpen: false,
      activeTabIndex: 0,
    };

    this.toggleFilterDialog = this.toggleFilterDialog.bind(this);
    // this.filterData = this.filterData.bind(this);
  }

  componentDidMount() {
    const { bulletinBoardStore, uiStore, uiStore: { currentUser } } = this.props;
    // currentUser: { accountInfo: { thirdPartyApiIntegrations: { procountor } } }
    // const bulletinBoardPosts = bulletinBoardStore.getAllBulletinBoardPosts(currentUser);
    this.queryBulletinBoardCategories(null, null, null);
    this.queryBulletinBoardPosts(null, null, null);

    if (uiStore.currentView.snackbarMessage) {
      this.setState({
        snackbarMessage: uiStore.currentView.snackbarMessage,
        openSnackbar: true,
      })
    }
  }

  toggleAddNewWorkOrder = () => {
    const { showAddNewWorkOrder } = this.state;

    if (!showAddNewWorkOrder) {
      this.setState({
        showAddNewWorkOrder: true,
      });
    } else {
      this.setState({
        showAddNewWorkOrder: false,
      });
    }
  }

  // addToTotalChatMessagesCount = (workOrderId) => {
  //   const { workOrders } = this.state;

  //   const foundWorkOrder = workOrders.find((wo) => wo.id === workOrderId);
  //   foundWorkOrder.chat.totalMessagesCount = (foundWorkOrder.chat.totalMessagesCount || 0) + 1;
  // }

  // getMoreWorkOrders() {
  //   const { employerWorkOrderStore } = this.props;
  //   employerWorkOrderStore.getMoreEmployerWorkOrders().then((newWorkOrders) => {
  //     this.setState((prevState) => ({
  //       currentWorkOrdersWithMeta: [...prevState.currentWorkOrdersWithMeta, ...newWorkOrders],
  //     }));
  //   })
  // }

  toggleFilterDialog() {
    this.setState((prevState) => ({
      filterDialogOpen: !prevState.filterDialogOpen,
    }));
  }

  // openEmployerChatDialog = (chatId) => {
  //   this.setState({
  //     employerChatDialogOpen: true,
  //     chatId: chatId,
  //   });
  // }

  // closeEmployerChatDialog = () => {
  //   this.setState({
  //     employerChatDialogOpen: false,
  //     chatId: null,
  //   });
  // }

  // filterData(filteredData, filterCount, activeFilters) {
  //   const { employerWorkOrderStore } = this.props;
  //   employerWorkOrderStore.setFilters(activeFilters);

  //   this.setState({
  //     currentWorkOrdersWithMeta: filteredData,
  //     filterCount,
  //     // activeFilters,
  //   });
  // }

  // queryWorkOrders(offset, defaultWorkOrdersPerPage = null, sort = null) {
  //   const { employerWorkOrderStore } = this.props;
  //   const { workOrdersPerPage } = this.state;

  //   const limit = workOrdersPerPage || defaultWorkOrdersPerPage;

  //   this.setState({
  //     isLoading: true,
  //     offset,
  //     workOrders: [],
  //     // workOrdersPerPage: limit,
  //   }, () => {
  //     employerWorkOrderStore.getEmployerWorkOrdersPaginated(offset, limit, sort).then((res) => {
  //       this.setState({
  //         isLoading: false,
  //         // page: res.page,
  //         // sortOrder,
  //         sort,
  //         workOrders: res.workOrders,
  //         workOrdersCount: res.count,
  //       }, () => {
  //         employerWorkOrderStore.cacheWorkOrders(res.workOrders, res.count, limit, offset, sort);
  //       });
  //     });
  //   });
  // }

  // convertSortOrderObjectToString = (sortObject) => {
  //   const { name, direction } = sortObject;
  //   if (!name || !direction) {
  //     return '';
  //   }

  //   let sort;
  //   if (!name.includes('.')) {
  //     // Assuming a work order column by default, we need the 'dot notation' here for postgreSQL query generation
  //     sort = `work_order.${name}-${direction}`;
  //   } else {
  //     sort = `${name}-${direction}`;
  //   }
  //   return sort;
  // }

  changePage = (page, sortOrder, rowsPerPage) => {
    const sort = this.convertSortOrderObjectToString(sortOrder);
    const offset = page * rowsPerPage;
    // this.queryWorkOrders(offset, null, sort);
    alert('QUERY');
  };

  changeRowsPerPage = (page, sortOrder, rowsPerPage) => {
    const sort = this.convertSortOrderObjectToString(sortOrder);

    this.setState({
      workOrdersPerPage: rowsPerPage,
    }, () => {
      const offset = page * rowsPerPage;
      // this.queryWorkOrders(offset, null, sort);
      alert('QUERY');
    })
  }

  changeSorting = (column, direction) => {
    let sort = this.convertSortOrderObjectToString({ name: column, direction: direction });

    this.setState({
      sort,
    }, () => {
      // Re-query using the new sorting, starting at offset 0
      // this.queryWorkOrders(0, null, sort);
    });
  }

  // renderResourceCalendarView() {
  //   const { schedulerData, originalData } = this.state;

  //   return (
  //     <div style={{ width: '100%' }}>
  //       {schedulerData && (
  //         <BasicResourceCalendarExample originalData={originalData} schedulerData={schedulerData} queryData={this.getAvailabilitiesScheduler} />
  //       )}
  //     </div>
  //   );
  // }

  renderBulletinBoardPostsTable() {
    const { uiStore, uiStore: { mobileMode, currentUser: { accountInfo } }, t } = this.props;
    const { bulletinBoardPosts, workOrdersCount, isLoading, workOrdersPerPage, offset } = this.state;

    if (isLoading) {
      return (
        <div style={{ padding: '20px', width: '100%', textAlign: 'center' }}>
          <LoadingSpinner color="black" />
        </div>
      )
    }

    const columns = [
      {
        name: 'id',
        label: 'ID',
        options: {
          display: 'excluded',
          filter: false,
        },
      },
      {
        name: 'title',
        label: 'Otsikko',
        options: {
          // customBodyRenderLite: (dataIndex) => {
          //   if (accountInfo.billingIdsEnabled && workOrders[dataIndex].billingId) {
          //     return (
          //       <div>
          //         <span>{workOrders[dataIndex].name}</span>
          //         <span className="work-order-billing-id">
          //           {` ${workOrders[dataIndex].billingId}`}
          //         </span>
          //       </div>
          //     );
          //   } else {
          //     return workOrders[dataIndex].name;
          //   }
          // },
          filter: false,
          // display: employerWorkOrderStore.billingDisplayColumns.name === undefined ? true : employerWorkOrderStore.billingDisplayColumns.name,
        },
      },
      {
        name: 'bulletinBoardCategory.name',
        label: 'Kategoria',
        options: {
          filter: true,
        },
      },
      {
        name: 'createdAt',
        label: 'Luotu',
        options: {
          customBodyRender: (value) => (
            <span style={{ textTransform: 'capitalize' }}>
              {value.local().format('dd DD.MM.YY HH:mm')}
            </span>
          ),
          filter: false,
        },
      },
      {
        name: 'files',
        label: 'Liitteet',
        options: {
          sort: false,
          filter: false,
          empty: true,
          // display: employerWorkOrderStore.billingDisplayColumns.attachments === undefined ? true : employerWorkOrderStore.billingDisplayColumns.attachments,
          customBodyRenderLite: (dataIndex) => {
            return (
              <span
                role="button"
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              // onClick={() => uiStore.setActiveTabAndShowWorkOrder('edit', bulletinBoardPosts[dataIndex].id, 3)}
              >
                {bulletinBoardPosts[dataIndex].files && bulletinBoardPosts[dataIndex].files.length !== 0 && (
                  <AttachFileIcon
                    fontSize="small"
                    style={{
                      color: 'black',
                      opacity: '1 !imporant',
                    }}
                  />
                )}
                <div className="invitations-neutral" style={{ minWidth: '18px', textAlign: 'center' }}>
                  {bulletinBoardPosts[dataIndex].files && bulletinBoardPosts[dataIndex].files.length > 0 ? bulletinBoardPosts[dataIndex].files.length : '-'}
                </div>
              </span>
            )
          }
        },
      },
      {
        name: 'edit',
        label: 'Muokkaa',
        options: {
          sort: false,
          filter: false,
          empty: true,
          // display: employerWorkOrderStore.billingDisplayColumns.report === undefined ? true : employerWorkOrderStore.billingDisplayColumns.report,
          customBodyRenderLite: (dataIndex) => {
            return (
              <Link
                component="button"
                // onClick={() => uiStore.showEmployerWorkOrder('edit', bulletinBoardPosts[dataIndex].id)}
                onClick={() => this.openBulletinBoardPostDialog(bulletinBoardPosts[dataIndex])}
              >
                Muokkaa
              </Link>
            )
          }
        },
      },
    ];

    const options = {
      page: Math.floor((offset + 1) / workOrdersPerPage),
      serverSide: true,
      count: workOrdersCount,
      filter: false,
      sort: true,
      filterType: 'checkbox',
      responsive: 'standard',
      search: false,
      viewColumns: true,
      selectableRows: 'none',
      fixedHeader: false,
      download: false,
      print: false,
      enableNestedDataAccess: '.',
      rowsPerPage: workOrdersPerPage,
      rowsPerPageOptions: [25, 50, 100],
      setTableProps: () => ({ className: 'mui-data-table-billing' }),
      setRowProps: () => {
        return (
          {
            style: {
              cursor: 'pointer',
            },
          }
        );
      },
      onRowClick: this.handlePostRowClick,
      textLabels: {
        body: {
          noMatch: (
            isLoading ? (
              <div style={{ textAlign: uiStore.mobileMode ? 'left' : 'center', padding: '20px', fontSize: '14px' }}>
                <LoadingSpinner color="black" />
              </div>
            ) : (
              'Ilmoituksia ei löytynyt'
            )
          )
        },
        toolbar: {
          search: 'Haku',
          viewColumns: 'Näytä sarakkeet',
          filterTable: 'Suodatin',
        },
        // pagination: {
        //   next: "Seuraava sivu",
        //   previous: "Edellinen",
        //   rowsPerPage: "Rivejä sivulla:",
        //   displayRows: "/",
        // },
        filter: {
          all: "Kaikki",
          title: "SUODATIN",
          reset: "Tyhjennä",
        },
        viewColumns: {
          title: "Näytä sarakkeet",
          titleAria: "Näytä/piilota sarakkeet",
        },
        selectedRows: {
          text: "rivi(ä) valittu",
          delete: "Poista",
          deleteAria: "Poista valitut rivit",
        },
      },
      // onTableChange: (action, tableState) => {
      //   switch (action) {
      //     case 'changePage':
      //       this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
      //       break;
      //     case 'changeRowsPerPage':
      //       this.changeRowsPerPage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
      //     case 'sort':
      //       if (tableState.sortOrder.name) {
      //         this.changeSorting(tableState.sortOrder.name, tableState.sortOrder.direction);
      //       }
      //       break;
      //     default:
      //       console.log('action not handled.');
      //   }
      // },
    };

    return (
      <div
        style={{
          width: '100%',
          padding: '20px',
          // display: mobileMode ? 'none' : 'initial',
        }}
        className={`mui-billing-table ${mobileMode ? 'mobile-billing-table' : null}`}
      >
        <MUIDataTable
          // title={
          //   isLoading && (
          //     <div style={{ textAlign: 'center', padding: '20px', fontSize: '14px' }}>
          //       Ladataan
          //       <LoadingSpinner color="black" />
          //     </div>
          //   )
          // }
          data={bulletinBoardPosts}
          columns={columns}
          options={options}
        />
      </div>
    );
  }

  renderCategoriesTable() {
    const { uiStore, uiStore: { mobileMode, currentUser: { accountInfo } }, t } = this.props;
    const { bulletinBoardCategories, isLoading, workOrdersCount, workOrdersPerPage, offset } = this.state;

    if (isLoading) {
      return (
        <div style={{ padding: '20px', width: '100%', textAlign: 'center' }}>
          <LoadingSpinner color="black" />
        </div>
      )
    }

    const columns = [
      {
        name: 'id',
        label: 'ID',
        options: {
          display: 'excluded',
          filter: false,
        },
      },
      {
        name: 'name',
        label: 'Nimi',
        options: {
          filter: false,
          // display: employerWorkOrderStore.billingDisplayColumns.name === undefined ? true : employerWorkOrderStore.billingDisplayColumns.name,
        },
      },
      {
        name: 'createdAt',
        label: 'Luotu',
        options: {
          customBodyRender: (value) => (
            <span style={{ textTransform: 'capitalize' }}>
              {value.local().format('dd DD.MM.YY HH:mm')}
            </span>
          ),
          filter: false,
        },
      },
      {
        name: 'edit',
        label: 'Muokkaa',
        options: {
          sort: false,
          filter: false,
          empty: true,
          // display: employerWorkOrderStore.billingDisplayColumns.report === undefined ? true : employerWorkOrderStore.billingDisplayColumns.report,
          customBodyRenderLite: (dataIndex) => {
            return (
              <Link
                component="button"
                // onClick={() => uiStore.showEmployerWorkOrder('edit', bulletinBoardPosts[dataIndex].id)}
                onClick={() => this.openBulletinBoardCategoryDialog(bulletinBoardCategories[dataIndex])}
              >
                Muokkaa
              </Link>
            )
          }
        },
      },
    ];

    const options = {
      page: Math.floor((offset + 1) / workOrdersPerPage),
      serverSide: true,
      count: workOrdersCount,
      filter: false,
      sort: true,
      filterType: 'checkbox',
      responsive: 'standard',
      search: false,
      viewColumns: true,
      selectableRows: 'none',
      fixedHeader: false,
      download: false,
      print: false,
      enableNestedDataAccess: '.',
      rowsPerPage: workOrdersPerPage,
      rowsPerPageOptions: [25, 50, 100],
      setTableProps: () => ({ className: 'mui-data-table-billing' }),
      setRowProps: () => {
        return (
          {
            style: {
              cursor: 'pointer',
            },
          }
        );
      },
      onRowClick: this.handleCategoryRowClick,
      textLabels: {
        body: {
          noMatch: (
            isLoading ? (
              <div style={{ textAlign: uiStore.mobileMode ? 'left' : 'center', padding: '20px', fontSize: '14px' }}>
                <LoadingSpinner color="black" />
              </div>
            ) : (
              'Ilmoituksia ei löytynyt'
            )
          )
        },
        toolbar: {
          search: 'Haku',
          viewColumns: 'Näytä sarakkeet',
          filterTable: 'Suodatin',
        },
        // pagination: {
        //   next: "Seuraava sivu",
        //   previous: "Edellinen",
        //   rowsPerPage: "Rivejä sivulla:",
        //   displayRows: "/",
        // },
        filter: {
          all: "Kaikki",
          title: "SUODATIN",
          reset: "Tyhjennä",
        },
        viewColumns: {
          title: "Näytä sarakkeet",
          titleAria: "Näytä/piilota sarakkeet",
        },
        selectedRows: {
          text: "rivi(ä) valittu",
          delete: "Poista",
          deleteAria: "Poista valitut rivit",
        },
      },
      // onTableChange: (action, tableState) => {
      //   switch (action) {
      //     case 'changePage':
      //       this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
      //       break;
      //     case 'changeRowsPerPage':
      //       this.changeRowsPerPage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
      //     case 'sort':
      //       if (tableState.sortOrder.name) {
      //         this.changeSorting(tableState.sortOrder.name, tableState.sortOrder.direction);
      //       }
      //       break;
      //     default:
      //       console.log('action not handled.');
      //   }
      // },
    };

    return (
      <div
        style={{
          width: '100%',
          padding: '20px',
          // display: mobileMode ? 'none' : 'initial',
        }}
        className={`mui-billing-table ${mobileMode ? 'mobile-billing-table' : null}`}
      >
        <MUIDataTable
          // title={
          //   isLoading && (
          //     <div style={{ textAlign: 'center', padding: '20px', fontSize: '14px' }}>
          //       Ladataan
          //       <LoadingSpinner color="black" />
          //     </div>
          //   )
          // }
          data={bulletinBoardCategories}
          columns={columns}
          options={options}
        />
      </div>
    );
  }

  handlePostRowClick = (rowData, rowMeta) => {
    const { bulletinBoardPosts } = this.state;

    const selectedRowIndex = rowMeta.dataIndex;
    const clickedBulletinBoardPost = bulletinBoardPosts[selectedRowIndex];

    // uiStore.showEmployerWorkOrder('edit', clickedBulletinBoardPost.id);
    this.openBulletinBoardPostDialog(clickedBulletinBoardPost);
  }

  handleCategoryRowClick = (rowData, rowMeta) => {
    const { bulletinBoardCategories } = this.state;

    const selectedRowIndex = rowMeta.dataIndex;
    const clickedCategory = bulletinBoardCategories[selectedRowIndex];

    // uiStore.showEmployerWorkOrder('edit', clickedBulletinBoardPost.id);
    this.openBulletinBoardCategoryDialog(clickedCategory);
  }

  saveBulletinBoardPost = (bulletinBoardPost) => {
    const { bulletinBoardStore } = this.props;

    if (bulletinBoardPost.id == null || bulletinBoardPost.id === '') {
      const createPromise = fromPromise(new Promise((resolve, reject) => bulletinBoardStore.createBulletinBoardPost(bulletinBoardPost, resolve, reject)));
      when(
        () => createPromise.state !== 'pending',
        () => {
          createPromise.case({
            pending: () => { },
            rejected: (err) => {
              console.log('Tallennus epäonnistui', err);
            },
            fulfilled: (res) => {
              console.log('GOT CREATED POST: ', res);
              this.setState((prevState) => ({
                openedBulletinBoardPost: {},
                bulletinBoardPostDialogOpen: false,
                bulletinBoardPosts: [res, ...prevState.bulletinBoardPosts],
              }));
            },
          });
        },
      );
    } else {
      const updatePromise = fromPromise(new Promise((resolve, reject) => bulletinBoardStore.updateBulletinBoardPost(bulletinBoardPost, resolve, reject)));
      when(
        () => updatePromise.state !== 'pending',
        () => {
          updatePromise.case({
            pending: () => { },
            rejected: (err) => {
              console.log('Tallennus epäonnistui', err);
            },
            fulfilled: (res) => {
              const { bulletinBoardPosts } = this.state;
              console.log('RESULT: ', res);
              const foundBulletinBoardPostIndex = bulletinBoardPosts.findIndex((post) => post.id === res.id);
              const updatedBulletinBoardPosts = [...bulletinBoardPosts];
              if (foundBulletinBoardPostIndex !== -1) {
                updatedBulletinBoardPosts[foundBulletinBoardPostIndex] = res;
              }
              this.setState({
                openedBulletinBoardPost: {},
                bulletinBoardPostDialogOpen: false,
                bulletinBoardPosts: updatedBulletinBoardPosts,
              });
            },
          });
        },
      );
    }
  }

  saveBulletinBoardCategory = (bulletinBoardCategory) => {
    const { bulletinBoardStore } = this.props;

    if (bulletinBoardCategory.id == null || bulletinBoardCategory.id === '') {
      const createPromise = fromPromise(new Promise((resolve, reject) => bulletinBoardStore.createBulletinBoardCategory(bulletinBoardCategory, resolve, reject)));
      when(
        () => createPromise.state !== 'pending',
        () => {
          createPromise.case({
            pending: () => { },
            rejected: (err) => {
              console.log('Tallennus epäonnistui', err);
            },
            fulfilled: (res) => {
              this.setState((prevState) => ({
                openedBulletinBoardCategory: {},
                bulletinBoardCategoryDialogOpen: false,
                bulletinBoardCategories: [res, ...prevState.bulletinBoardCategories],
                createdBulletinBoardCategory: res,
              }));
            },
          });
        },
      );
    } else {
      const updatePromise = fromPromise(new Promise((resolve, reject) => bulletinBoardStore.updateBulletinBoardCategory(bulletinBoardCategory, resolve, reject)));
      when(
        () => updatePromise.state !== 'pending',
        () => {
          updatePromise.case({
            pending: () => { },
            rejected: (err) => {
              console.log('Tallennus epäonnistui', err);
            },
            fulfilled: (res) => {
              const { bulletinBoardCategories } = this.state;
              const foundBulletinBoardCategoryIndex = bulletinBoardCategories.findIndex((post) => post.id === res.id);
              const updatedBulletinBoardCategories = [...bulletinBoardCategories];
              if (foundBulletinBoardCategoryIndex !== -1) {
                updatedBulletinBoardCategories[foundBulletinBoardCategoryIndex] = res;
              }
              this.setState({
                openedBulletinBoardCategory: {},
                bulletinBoardCategoryDialogOpen: false,
                bulletinBoardCategories: updatedBulletinBoardCategories,
              });
            },
          });
        },
      );
    }
  }

  queryBulletinBoardCategories() {
    const { bulletinBoardStore } = this.props;

    this.setState({
      bulletinBoardCategories: [],
      // workOrdersPerPage: limit,
    }, () => {
      const bulletinBoardCategoriesPromise = fromPromise(new Promise((resolve, reject) => bulletinBoardStore.getAllBulletinBoardCategories(resolve, reject)));

      when(
        () => bulletinBoardCategoriesPromise.state !== 'pending',
        () => {
          bulletinBoardCategoriesPromise.case({
            pending: () => {
              // this.setState({ isLoading: true });
            },
            rejected: (err) => {
              // this.setState({ isLoading: false });
              console.error('ERROR: ', err);
            },
            fulfilled: (res) => {
              console.log('Res: ', res);
              // const defaultSalaryPeriodCategory = res.salary_period_categories.find((cat) => cat.is_default);
              // const defaultSalaryPeriod = res.current_salary_periods.find((sp) => sp.salary_period_category_id === defaultSalaryPeriodCategory.id);
              this.setState({
                bulletinBoardCategories: res,
              });
            },
          });
        },
      );
    });
  }

  queryBulletinBoardPosts(offset, defaultBulletinBoardPostsPerPage = null, sort = null) {
    const { bulletinBoardStore } = this.props;
    const { bulletinBoardPostsPerPage } = this.state;

    const limit = bulletinBoardPostsPerPage || defaultBulletinBoardPostsPerPage;

    this.setState({
      isLoading: true,
      offset,
      bulletinBoardPosts: [],
      // workOrdersPerPage: limit,
    }, () => {
      const bulletinBoardPostsPromise = fromPromise(new Promise((resolve, reject) => bulletinBoardStore.getAllBulletinBoardPosts(resolve, reject)));

      when(
        () => bulletinBoardPostsPromise.state !== 'pending',
        () => {
          bulletinBoardPostsPromise.case({
            pending: () => {
              // this.setState({ isLoading: true });
            },
            rejected: (err) => {
              // this.setState({ isLoading: false });
              console.error('ERROR: ', err);
            },
            fulfilled: (res) => {
              console.log('Res: ', res);
              // const defaultSalaryPeriodCategory = res.salary_period_categories.find((cat) => cat.is_default);
              // const defaultSalaryPeriod = res.current_salary_periods.find((sp) => sp.salary_period_category_id === defaultSalaryPeriodCategory.id);
              this.setState({
                isLoading: false,
                bulletinBoardPosts: res,
                // salaryPeriodCategories: res.salary_period_categories,
                // currentSalaryPeriods: res.current_salary_periods,
                // selectedSalaryPeriod: defaultSalaryPeriod,
              });
            },
          });
        },
      );

      // employerWorkOrderStore.getEmployerWorkOrdersBillingPaginated(offset, limit, sort).then((res) => {
      // bulletinBoardStore.getAllBulletinBoardPosts().then((res) => {
      //   console.log('RES: ', res);
      //   this.setState({
      //     isLoading: false,
      //     // page: res.page,
      //     // sortOrder,
      //     sort,
      //     bulletinBoardPosts: res.bulletinBoardPosts,
      //     bulletinBoardPostsCount: res.count,
      //   }, () => {
      //     // employerWorkOrderStore.cacheBillingWorkOrders(res.workOrders, res.count, limit, offset, sort);
      //   });
      // });
    });
  }

  renderSnackbar() {
    const { openSnackbar, snackbarMessage } = this.state;

    return (
      <Snackbar
        open={openSnackbar}
        style={{
          textAlign: 'center',
          top: '0'
        }}
        onClose={() => {
          this.setState({
            openSnackbar: false,
            saveStatusMessage: '',
          });
        }}
        message={snackbarMessage}
        timeout={2000}
      />
    )
  }

  openBulletinBoardPostDialog = (openedBulletinBoardPost) => {
    this.setState({
      openedBulletinBoardPost,
      bulletinBoardPostDialogOpen: true,
      createdBulletinBoardCategory: null,
    });
  }

  openBulletinBoardCategoryDialog = (openedBulletinBoardCategory) => {
    this.setState({
      openedBulletinBoardCategory,
      bulletinBoardCategoryDialogOpen: true,
    });
  }

  closeBulletinBoardPostDialog = () => {
    this.setState({
      bulletinBoardPostDialogOpen: false,
      openedBulletinBoardPost: {},
    });
  }

  closeBulletinBoardCategoryDialog = () => {
    this.setState({
      bulletinBoardCategoryDialogOpen: false,
      openedBulletinBoardCategory: {},
    });
  }

  renderBulletinBoardPostDialog() {
    const { bulletinBoardPostDialogOpen, openedBulletinBoardPost, createdBulletinBoardCategory, bulletinBoardCategories } = this.state;
    return (
      <BulletinBoardPostDialog
        open={bulletinBoardPostDialogOpen}
        handleClose={this.closeBulletinBoardPostDialog}
        bulletinBoardPost={openedBulletinBoardPost}
        saveBulletinBoardPost={this.saveBulletinBoardPost}
        openBulletinBoardCategoryDialog={this.openBulletinBoardCategoryDialog}
        createdBulletinBoardCategory={createdBulletinBoardCategory}
        bulletinBoardCategories={bulletinBoardCategories}
        confirmDelete={this.confirmDelete}
      />
    );
  }

  renderBulletinBoardCategoryDialog() {
    const { bulletinBoardCategoryDialogOpen, openedBulletinBoardCategory } = this.state;
    return (
      <BulletinBoardCategoryDialog
        open={bulletinBoardCategoryDialogOpen}
        handleClose={this.closeBulletinBoardCategoryDialog}
        bulletinBoardCategory={openedBulletinBoardCategory}
        saveBulletinBoardCategory={this.saveBulletinBoardCategory}
        confirmDelete={this.confirmDelete}
      />
    );
  }

  confirmDelete = (mode, name, id) => {
    this.setState({
      deleteDialogOpen: true,
      deletingRecordName: name,
      deletingRecordId: id,
      deletingMode: mode,
    });
  }

  handleDeletePost = (id) => {
    const { bulletinBoardStore } = this.props

    const deletePromise = fromPromise(new Promise((resolve, reject) => bulletinBoardStore.deleteBulletinBoardPost(id, resolve, reject)));
    when(
      () => deletePromise.state !== 'pending',
      () => {
        deletePromise.case({
          pending: () => { },
          rejected: (err) => {
            console.log('Poistaminen epäonnistui: ', err);
          },
          fulfilled: () => {
            // DELETE FROM STATE
            this.setState((prevState) => ({
              bulletinBoardPosts: [...prevState.bulletinBoardPosts.filter((post) => post.id !== id)],
              deleteDialogOpen: false,
              bulletinBoardPostDialogOpen: false,
            }));
          },
        });
      },
    );
  }

  handleDeleteCategory = (id) => {
    const { bulletinBoardStore } = this.props;

    console.log('Deleting category: ', id);

    const deletePromise = fromPromise(new Promise((resolve, reject) => bulletinBoardStore.deleteBulletinBoardCategory(id, resolve, reject)));
    when(
      () => deletePromise.state !== 'pending',
      () => {
        deletePromise.case({
          pending: () => { },
          rejected: (err) => {
            console.log('Poistaminen epäonnistui: ', err);
          },
          fulfilled: () => {
            // DELETE FROM STATE
            this.setState((prevState) => ({
              bulletinBoardCategories: [...prevState.bulletinBoardCategories.filter((category) => category.id !== id)],
              deleteDialogOpen: false,
              bulletinBoardCategoryDialogOpen: false,
            }));
          },
        });
      },
    );
  }

  selectTab(index) {
    this.setState({ activeTabIndex: index });
  }

  closeDeleteDialog() {
    this.setState({
      deleteDialogOpen: false,
    });
  }

  renderDeleteDialog() {
    const { deleteDialogOpen, deletingRecordName, deletingMode, deletingRecordId } = this.state;
    console.log('Deleting mode: ', deletingMode);
    let handleDeleteMethod;
    let modeText;
    switch (deletingMode) {
      case 'bulletinBoardPost':
        modeText = 'ilmoituksen';
        handleDeleteMethod = this.handleDeletePost;
        break;
      case 'bulletinBoardCategory':
        modeText = 'kategorian';
        handleDeleteMethod = this.handleDeleteCategory;
        break;
      default:
        modeText = '';
    };

    return (
      <Dialog
        className="employer-delete-modal"
        open={deleteDialogOpen}
        onClose={() => closeDeleteDialog()}
      >
        <DialogContent
          style={{ fontSize: '16px' }}
        >
          <p>{`Haluatko varmasti poistaa ${modeText} ${deletingRecordName}?`}</p>
        </DialogContent>

        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            type="button"
            className="employer-accept-button"
            onClick={() => handleDeleteMethod && handleDeleteMethod(deletingRecordId)}
          >
            Kyllä
          </Button>

          <Button
            type="button"
            className="employer-reject-button"
            onClick={() => this.closeDeleteDialog()}
          >
            Peruuta
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { t } = this.props;
    // const { employerWorkOrderStore: { workOrderFilters }, t, uiStore } = this.props;
    const {
      // filterDialogOpen,
      // filterCount,
      // currentWorkOrdersWithMeta,
      // employerChatDialogOpen,
      // chatId,
      bulletinBoardPosts,
      bulletinBoardCategories,
      // offset,
      // workOrdersCount,
      // workOrdersPerPage,
      viewMode,
      // showCalendar,
      // calendarMode,
      // schedulerData,
      // originalData,
      activeTabIndex,
    } = this.state; // activeFilters

    return (
      <>
        {/* <Grid
          style={{ width: '100%', height: '100%', padding: '0 0 16px 0' }}
        >
          <GridInner
            style={{ rowGap: '0px' }}
          >
            <GridCell
              span={12}
            >
              <div
                style={{
                  padding: '10px',
                  width: '100%',
                  // textAlign: 'end',
                  // maxWidth: '350px',
                  display: 'flex',
                }}
                className="filter-button"
              >
                <div className="view-heading">
                  <div style={{ flexDirection: 'column', justifyContent: 'center' }}>
                    Ilmoitustaulu
                  </div>
                </div>

                <>
                  <Button
                    startIcon={<AddIcon />}
                    className="employer-accept-button"
                    style={{
                      width: 'fit-content',
                      minWidth: '160px',
                      height: 'fit-content',
                      margin: 'auto 0',
                      marginRight: '10px',
                    }}
                    onClick={() => this.openBulletinBoardPostDialog(new BulletinBoardPost({}))}
                  >
                    Uusi ilmoitus
                  </Button>
                </>
              </div>
            </GridCell>

            {viewMode === 'desktop' && (
              <GridCell span={12}>
                {this.renderWorkOrderTable(bulletinBoardPosts, 'Ei ilmoituksia')}
              </GridCell>
            )}
          </GridInner>
        </Grid> */}

        <div
          style={{
            padding: '10px',
            width: '100%',
            // textAlign: 'end',
            // maxWidth: '350px',
            display: 'flex',
          }}
          className="filter-button"
        >
          <div className="view-heading">
            {/* <div style={{ flexDirection: 'column', justifyContent: 'center' }}>
              {t('view_titles.bulletin-board-posts')}
            </div> */}
            <div>
              <span
                role="button"
                onClick={() => this.selectTab(0)}
                className={`heading-tab ${activeTabIndex === 0 ? 'active' : 'inactive'}`}
                style={{ marginLeft: 0 }}
              >
                {t('view_titles.bulletin-board-posts')}
              </span>
              <span role="button" onClick={() => this.selectTab(1)} className={`heading-tab ${activeTabIndex === 1 ? 'active' : 'inactive'}`}>
                Kategoriat
              </span>
            </div>
          </div>

          <>
            {activeTabIndex === 0 && (
              <Button
                startIcon={<AddIcon />}
                className="employer-accept-button"
                style={{
                  width: 'fit-content',
                  minWidth: '160px',
                  height: 'fit-content',
                  margin: 'auto 0',
                  marginRight: '10px',
                }}
                onClick={() => this.openBulletinBoardPostDialog(new BulletinBoardPost({}))}
              >
                {/* {uiStore.mobileMode ? 'Uusi' : 'Uusi keikka'} */}
                Uusi ilmoitus
              </Button>
            )}
            {activeTabIndex === 1 && (
              <Button
                startIcon={<AddIcon />}
                className="employer-accept-button"
                style={{
                  width: 'fit-content',
                  minWidth: '170px',
                  height: 'fit-content',
                  margin: 'auto 0',
                  marginRight: '10px',
                }}
                onClick={() => this.openBulletinBoardCategoryDialog(new BulletinBoardCategory({}))}
              >
                Uusi kategoria
              </Button>
            )}
          </>
        </div>

        {activeTabIndex === 0 && (
          this.renderBulletinBoardPostsTable(bulletinBoardPosts, 'Ei ilmoituksia')
        )}

        {activeTabIndex === 1 && (
          this.renderCategoriesTable(bulletinBoardCategories, 'Ei kategorioita')
        )}

        {this.renderSnackbar()}
        {this.renderBulletinBoardCategoryDialog()}
        {this.renderBulletinBoardPostDialog()}
        {this.renderDeleteDialog()}
      </>
    );
  }
}

export default EmployerBulletinBoardPosts;
