import { action, observable } from 'mobx';
import moment from '../utils/moment';

export default class BulletinBoardCategory {
  @observable id;

  @observable name;

  @observable description;

  constructor(object) {
    this.updateProperties(object);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static toJson(o) {
    return {
      name: o.name,
      id: o.id,
      description: o.description,
    };
  }

  @action updatePropertiesFromJson(o) {
    const values = {
      createdAt: moment(o.created_at).local(),
      name: o.name,
      id: o.id,
      description: o.description,
    };

    return this.updateProperties(values);
  }

  static fromJsonProperties(o) {
    const bulletinBoardCategory = new BulletinBoardCategory();
    bulletinBoardCategory.updatePropertiesFromJson(o);
    return bulletinBoardCategory;
  }
}
