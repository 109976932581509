import { Router } from 'director/build/director';
import { autorun } from 'mobx';

export default function startRouter(store) {
  // update state on url change
  const router = Router({
    '/': () => store.showCalendar(),
    '/app/login': () => store.showLogin(),
    '/app/registration/:token': (token) => store.showRegistration(token),
    '/app/calendar': () => store.showCalendar(),
    '/app/settings': () => store.showSettings(),
    '/app/profile': () => store.showProfile(),
    '/app/messages': () => store.showMessages(),
    '/app/timelog': () => store.showTimeEntries(),
    '/app/chat/:id': (id) => store.showChatRoom(id),
    '/app/bulletin-board-category/:id': (id) => store.showBulletinBoardCategory(id),
    '/app/work-orders/:id': (id) => store.showWorkOrder(id),
    '/app/invitations/accept/:token': (token) => store.showInvitation(token),
    '/app/employer-work-orders/': () => store.showEmployerWorkOrders(),
    '/app/locations-purchasers/': () => store.showLocationsPurchasers(),
    '/app/employees/': () => store.showEmployees(),
    '/app/products/': () => store.showProducts(),
    'app/resource-availability/': () => store.showResourceAvailability(),
    '/app/employer-timelogs/': () => store.showEmployerTimelogs(),
    '/app/timelog-report/': () => store.showTimelogReport(),
    '/app/employer-work-order-trips/': () => store.showEmployerWorkOrderTrips(),
    '/app/employer-absences/': () => store.showEmployerAbsences(),
    // 'app/employer-work-order/:id': (id) => store.showEmployerWorkOrder(id),
    'app/employer-work-order/:id': () => store.showEmployerWorkOrders(),
    'app/employer-billing/': () => store.showEmployerBilling(),
    'app/employer-billing-attachments': () => store.showEmployerBilling(),
    'app/employer-billing-reports/:id': () => store.showBillingReports(),
    'app/employer-billing-reports-hourly/:id': () => store.showBillingReportsHourly(),
    'app/employer-product-report/:id': () => store.showProductReport(),
    'app/payroll-administration': () => store.showPayrollAdministration(),
    'app/safety': () => store.showSafety(),
    'app/employer-close-call-reports': () => store.showEmployerCloseCallReports(),
    'app/accident-reports': () => store.showAccidentReports(),
    'app/salary-period-processing': () => store.showSalaryPeriodProcessing(),
    'app/certificates': () => store.showCertificates(),
    'app/reset-password/:token': (token) => store.showChangePassword(token),
    'app/bulletin-board-posts': () => store.showBulletinBoardPosts(),
  });

  router.configure({
    // Always route to the home page
    notfound: () => {
      console.log(
        `====> path ${store.currentPath} not found, routing to calendar`,
      );
      store.showCalendar();
    },
    html5history: true,
    strict: false,
  });

  router.init();

  // update url on state changes
  autorun(() => {
    const path = store.currentPath;
    console.log('====> current path is', path);
    if (path !== window.location.pathname) {
      window.history.pushState(null, null, path);
    }
  });
}
