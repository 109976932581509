import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import { TextField } from '@rmwc/textfield';
// import { Grid, GridCell } from '@rmwc/grid';
// import {
//   Dialog, DialogTitle, DialogActions, DialogButton, DialogContent,
// } from '@rmwc/dialog';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  ThemeProvider,
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import BulletinBoardPost from '../../models/BulletinBoardPost';
import theme from '../../muiTheme';
import './BulletinBoardPostDialog.css';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

const nameValidate = (name) => {
  if (!name) {
    return 'Tilaajan nimi on pakollinen';
  }
  return null;
};


const streetValidate = (street) => {
  if (!street) {
    return 'Katuosoite on pakollinen';
  }
  return null;
};

const cityValidate = (city) => {
  if (!city) {
    return 'Paikkakunta on pakollinen';
  }
  return null;
};

const validate = {
  // attachments: (description, files) => descriptionOrFileValidation(description, files),
  name: (name) => nameValidate(name),
  street: (street) => streetValidate(street),
  city: (city) => cityValidate(city),
};

@inject('bulletinBoardStore', 'loginStore', 't')
@observer
class BulletinBoardCategoryDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ...props.purchaser,
      bulletinBoardCategory: props.bulletinBoardCategory,
      // id: props.id,
      // name: props.dialogValue || props.purchaser?.name || '',
      // purchaser: props.purchaser,
      errors: {},
      inputChanged: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { bulletinBoardCategory, open } = this.props;

    // Check if the dialog has just been opened
    if ((!prevProps.open && open) || prevProps.bulletinBoardCategory?.id !== bulletinBoardCategory?.id) {
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({
        // id: purchaser?.id || '',
        // name: dialogValue || purchaser?.name || '',
        // street: purchaser?.street || '',
        // zipCode: purchaser?.zipCode || '',
        // city: purchaser?.city || '',
        bulletinBoardCategory,
        errors: {},
        inputChanged: false,
      });
      // Original first case changed to here
    }
    // else if (prevProps.purchaser?.id !== purchaser?.id) {
    //   /* eslint-disable-next-line react/no-did-update-set-state */
    //   this.setState({
    //     // id: purchaser?.id || '',
    //     // name: purchaser?.name || dialogValue || '',
    //     // street: purchaser?.street || '',
    //     // zipCode: purchaser?.zipCode || '',
    //     // city: purchaser?.city || '',
    //     bulletinBoardCategory: bulletinBoardCategory,
    //     errors: {},
    //   });
    // }
  }

  // setFile(file) {
  //   this.setState((prevState) => ({
  //     bulletinBoardCategory: { ...prevState.bulletinBoardCategory, files: [...prevState.bulletinBoardCategory.files, file] },
  //   }));
  // }

  addTextField(attr, label, required, textarea, styles = {}) {
    const { errors, bulletinBoardCategory } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <TextField {...{
          // className: 'employer-dialog-input',
          className: 'input-hacked-paddings',
          label,
          required,
          name: attr,
          rows: 5,
          invalid: errors[attr],
          helperText: errors[attr] && errors[attr],
          multiline: textarea,
          onChange: (event) => {
            const { value } = event.target;

            const updatedBulletinBoardPost = new BulletinBoardPost(bulletinBoardCategory);
            updatedBulletinBoardPost.changeAttribute(attr, value);
            this.setState({
              // [attr]: value,
              bulletinBoardCategory: updatedBulletinBoardPost,
              inputChanged: true,
            });

            delete errors[attr];
          },
          // rootProps: { ripple: false },
          onKeyPress: !textarea ? handleEnter : () => { },
          /* eslint-disable-next-line react/destructuring-assignment */
          value: bulletinBoardCategory[attr] != null ? bulletinBoardCategory[attr] : '',
          variant: 'filled',
          style: { ...styles },
          // style: {
          //   backgroundColor: '#FFFFFF',
          //   width: '100%',
          //   margin: '0.3em 0',
          //   whiteSpace: 'pre',
          // },
        }}
        />
      </ThemeProvider>
    );
  }

  inputDropDown(attr, label, required, styles) {
    const { bulletinBoardCategory, errors } = this.state;
    const { bulletinBoardCategories } = this.props;

    // Sort the purchasers and locations alphabetically
    const sortedCategories = bulletinBoardCategories?.slice().sort((a, b) => a.name.localeCompare(b.name, 'fi'));

    return (
      <ThemeProvider theme={theme}>
        <Autocomplete
          freeSolo
          options={sortedCategories || []}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          value={bulletinBoardCategory?.[attr] || null}
          // eslint-disable-next-line react/destructuring-assignment
          inputValue={this.state[`inputValue_${attr}`] || ''}
          onInputChange={(event, newInputValue) => {
            this.setState({ [`inputValue_${attr}`]: newInputValue });
          }}
          onChange={(event, newValue) => {
            delete errors[attr];

            if (typeof newValue === 'string') {
              this.openBulletinBoardCategoryDialog(newValue);
              this.setState({ [`inputValue_${attr}`]: '' }); // Clear the input after opening dialog
            } else if (newValue && newValue.inputValue) {
              this.openBulletinBoardCategoryDialog(newValue.inputValue);
              this.setState({ [`inputValue_${attr}`]: '' }); // Clear the input after opening dialog
            } else if (newValue) {
              this.setState({
                bulletinBoardCategory: { ...bulletinBoardCategory, [attr]: newValue },
                [`inputValue_${attr}`]: newValue.name,
                inputChanged: true,
              });
            }
          }}
          filterOptions={(fOptions, params) => {
            const filtered = fOptions.filter((fOption) => (
              fOption.name.toLowerCase().includes(params.inputValue.toLowerCase())
            ));

            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Lisää "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          renderInput={(params) => (
            <TextField
              variant="filled"
              {...params}
              label={label}
              required={required}
              error={!!errors[attr]}
              helperText={errors[attr] && errors[attr]}
              style={styles}
            />
          )}
        />
      </ThemeProvider>
    );
  }

  openBulletinBoardCategoryDialog() {
    this.setState({
      bulletinBoardCategoryDialogOpen: true,
    });
  }

  renderActionButtons() {
    const {
      inputChanged,
      bulletinBoardCategory,
    } = this.state;
    const { id, handleClose, saveBulletinBoardCategory } = this.props;

    if (!id && !bulletinBoardCategory?.name) {
      return (
        <DialogActions
          style={{
            justifyContent: 'end',
            padding: '15px',
          }}
        >
          <Button
            // type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // action="close"
            onClick={() => handleClose()}
          >
            Sulje
          </Button>
        </DialogActions>
      );
    }
    if (!id && bulletinBoardCategory?.name) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            // type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // onClick={() => {
            //   this.handleValidate(name);
            // }}
            // action={name ? 'accept' : null}
            onClick={() => saveBulletinBoardCategory(bulletinBoardCategory)}
          >
            Tallenna
          </Button>

          <Button
            // type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // action="close"
            onClick={() => handleClose()}
          >
            Sulje
          </Button>
        </DialogActions>
      );
    }
    if (id && inputChanged) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            // type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
          // onClick={() => {
          //   this.handleValidate(name);
          // }}
          // action={name ? 'accept' : null}
          >
            Tallenna
          </Button>

          <Button
            // type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // action="close"
            onClick={() => handleClose()}
          >
            Peruuta
          </Button>
        </DialogActions>
      );
    }
    if (bulletinBoardCategory?.id && !inputChanged) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            // type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // action="close"
            onClick={() => handleClose()}
          >
            Sulje
          </Button>
        </DialogActions>
      );
    }
    return null;
  }

  render() {
    const {
      // t,
      open,
      handleClose,
      saveBulletinBoardCategory,
      confirmDelete,
    } = this.props;
    const { bulletinBoardCategory } = this.state;

    const styles = {
      backgroundColor: '#FFFFFF',
      width: '100%',
      margin: '0.3em 0',
    };

    return (
      <Dialog
        // className="modal-size employer-modal"
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            let errors = {};

            Object.keys(validate).forEach((key) => {
              /* eslint-disable-next-line react/destructuring-assignment */
              const value = this.state[key];

              // Calling the attr's validation method
              const error = validate[key](value);

              if (error) {
                errors = { ...errors, [key]: error };
              }
            });

            if (Object.keys(errors).length > 0) {
              this.setState({ errors });
            } else {
              // Transform the fields to start with uppercase
              // const transformedData = {
              //   ...this.state,
              //   title: title.charAt(0).toUpperCase() + title.slice(1),
              //   id: bulletinBoardCategory.id,
              // };
              saveBulletinBoardCategory(bulletinBoardCategory);
              handleClose();
            }
          } else {
            handleClose();
          }
        }}
      >
        <DialogTitle>
          {/* {bulletinBoardCategory.id ? 'Muokkaa kategoriaa' : 'Uusi kategoria'} */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ margin: 'auto 0' }}>
              {bulletinBoardCategory.id ? 'Muokkaa kategoriaa' : 'Uusi kategoria'}
            </div>
            {bulletinBoardCategory.id && (
              <IconButton
                style={{
                  color: '#645F5F',
                }}
                aria-label="delete"
                onClick={() => {
                  // this.confirmDeleteWorkPeriod();
                  confirmDelete('bulletinBoardCategory', bulletinBoardCategory.name, bulletinBoardCategory.id);
                }}
              >
                <DeleteOutline />
              </IconButton>
            )}
          </div>
        </DialogTitle>
        <DialogContent>
          {this.addTextField('name', 'Nimi', true, false, styles)}
          {this.addTextField('description', 'Kuvaus', false, true, styles)}
        </DialogContent>
        {this.renderActionButtons()}
      </Dialog>
    );
  }
}

export default BulletinBoardCategoryDialog;
